import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Router, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { ServiceWorkerModule } from '@angular/service-worker';

// Store Modules
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveComponentModule } from '@ngrx/component';

import {
  LY_THEME,
  LY_THEME_GLOBAL_VARIABLES,
  LY_THEME_NAME,
  LyHammerGestureConfig,
  LyTheme2,
  StyleRenderer
} from "@alyle/ui";
import { MinimaDark } from "@alyle/ui/themes/minima";
import { color } from '@alyle/ui/color';
import { LyImageCropperModule } from "@alyle/ui/image-cropper";
import { LySliderModule } from "@alyle/ui/slider";
import { SpeedTestModule } from 'ng-speed-test';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "@shared/shared.module";
import { CommonPagesModule } from "./common-pages/common-pages.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HomePageComponent } from '@modules/home-page/home-page.component';
import { AppComponent } from './app.component';

import { OpentokService } from "@services/opentok.service";
import { UserService } from "@services/user.service";

import { UrlInterceptor } from "./interseptors/url.interceptor";
import { JwtInterceptor } from "./interseptors/jwt.interceptor";

import { environment } from "@environments/environment";
import { EventBusService } from '@services/event.service';
import { MatDialogModule } from '@angular/material/dialog';
import { WidgetsService } from "@services/widgets.service";
import { ThemeService } from '@services/theme/theme.service';
import { SvgService } from '@services/svg.service';

// Store reducers, effects
import { reducers, metaReducers } from './reducers';
import { WidgetEffects } from './reducers/widget/widget.effects';
import { ProfileEffects } from './reducers/profile/profile.effects';
import { ServicesEffects } from './reducers/services/services.effects';
import { CallHistoryEffects } from './reducers/call-history/call-history.effects';
import { CommonEffects } from './reducers/common/common.effects';
import { PaymentsEffects } from './reducers/payments/payments.effects';

// Initialize app
import { initializeUser, initializeWidget } from './initialize-app/initializeApp';
import { WebSocketsService } from '@services/web-sockets/web-sockets.service';

export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e)
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xB36FBC)
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87)
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonPagesModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    SharedModule,
    LySliderModule,
    LyImageCropperModule,
    SpeedTestModule,
    HammerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatDialogModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    ServiceWorkerModule.register('../ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatDialogModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreDevtoolsModule.instrument({
      maxAge: 5
    }),
    EffectsModule.forRoot([
      WidgetEffects,
      ProfileEffects,
      ServicesEffects,
      CallHistoryEffects,
      CommonEffects,
      PaymentsEffects
    ]),
    ReactiveComponentModule,
  ],
  exports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeWidget,
      deps: [WidgetsService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUser,
      deps: [UserService, Store, Router],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    UserService,
    OpentokService,
    WebSocketsService,
    EventBusService,
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-dark' },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private themeService: ThemeService,
    private svgService: SvgService
  ) {
    this.themeService.setDefaultTheme();
    this.svgService.registryIcons();
  }
}
