import { Component, OnInit } from '@angular/core';
import { WidgetsService } from "@services/widgets.service";
import { STORAGE_ITEMS } from "@consts/storage";

@Component({
  selector: 'app-animated-logo',
  templateUrl: './animated-logo.component.html',
  styleUrls: ['./animated-logo.component.scss']
})
export class AnimatedLogoComponent implements OnInit{
  public widgetStatus: boolean;
  public frameStatus: boolean;

  constructor(public widgetsService: WidgetsService) {
  }

  ngOnInit(): void {
    this.setStatusApp();
  }

  setStatusApp(): void {
    const linkType = localStorage.getItem(STORAGE_ITEMS.LINK_TYPE);
    this.frameStatus = linkType ? linkType === 'FRAME' : false;
    this.widgetStatus = this.widgetsService.getWidgetStatus();
  }
}
