import { Action } from '@ngrx/store';
import { IWidgetsData } from '@interfaces/widgets.interface';

export enum widgetActionsType {
  loadWidgetData = '[Widget] Load widget data object',
  loadWidgetDataSuccess = '[Widget] Load widget data object success',
  loadWidgetDataError = '[Widget] Load widget data object error',
}

export class LoadWidgetDataAction implements Action {
  readonly type = widgetActionsType.loadWidgetData;

  constructor(public widgetId: number) {
  }
}

export class LoadWidgetDataSuccessAction implements Action {
  readonly type = widgetActionsType.loadWidgetDataSuccess;

  constructor(public data: IWidgetsData) {
  }
}

export class LoadWidgetDataErrorAction implements Action {
  readonly type = widgetActionsType.loadWidgetDataError;

  constructor(public error: string ) {
  }
}

export type WidgetActions = LoadWidgetDataAction | LoadWidgetDataSuccessAction | LoadWidgetDataErrorAction;
