/**
 * Created by Oleksandr V. on 6/16/2022.
 * Copyright © 2022 [Spotlyve]. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// Store
import {
  ClearProfileDataAction,
  DeleteProfileDataErrorAction,
  DeleteProfilePictureErrorAction,
  DeleteProfilePictureSuccessAction,
  LoadProfileDataErrorAction,
  LoadProfileDataSuccessAction, LogoutErrorAction,
  profileActionsType,
  UpdateProfileDataErrorAction,
  UpdateProfileDataSuccessAction, UpdateProfilePictureErrorAction, UpdateProfilePictureSuccessAction
} from './profile.actions';
import { ClearSingleServiceStateAction } from '../single-service/single-service.actions';
import { ToggleLoaderStateAction } from '../loader/loader.actions';

// Services
import { UserService } from '@services/user.service';
import { SystemNotificationsService } from '@services/system-notifications.service';

// Models
import { EUserProfileActions } from '@modules/user-profile/user-profile.enums';
import { CLocalStorageDefaultKeys } from '@consts/global';
import { STORAGE_ITEMS } from '@consts/storage';
import { ClearPaymentCardsAction } from '../payments/payments.actions';

@Injectable()
export class ProfileEffects {

  constructor(
    private actions$: Actions,
    private store$: Store,
    private router: Router,
    private dialog: MatDialog,
    private systemNotificationsService: SystemNotificationsService,
    private userService: UserService,
  ) {
  }

  loadProfileData$ = createEffect(() => this.actions$.pipe(
    ofType(profileActionsType.loadProfileData),
    switchMap(({ widgetId }) => {
      return this.userService.getProfile()
        .pipe(
          map((profileData) => new LoadProfileDataSuccessAction(profileData)),
          catchError((error) => of(new LoadProfileDataErrorAction(error)))
        );
    })
  ));

  logoutUser$ = createEffect(() => this.actions$.pipe(
    ofType(profileActionsType.logoutUser),
    switchMap(({ widgetId }) => {
      return this.userService.logoutUser()
        .pipe(
          map(() => {
            CLocalStorageDefaultKeys.forEach((key: string) => localStorage.removeItem(key));
            localStorage.setItem('link_type', 'WEB');
            this.store$.dispatch(new ClearSingleServiceStateAction());
            this.store$.dispatch(new ClearPaymentCardsAction());
            this.dialog.closeAll();
            this.router.navigate(['/']);
            return new ClearProfileDataAction();
          }),
          catchError((error) => {
            console.log('logoutUser$', error);
            this.systemNotificationsService.openErrorSnackBar(error?.error?.error);
            return of(new LogoutErrorAction(error));
          })
        );
    })
  ));

  updateProfileData$ = createEffect(() => this.actions$.pipe(
    ofType(profileActionsType.updateProfileData),
    switchMap(({ data }) => {
      return this.userService.updateProfile(data)
        .pipe(
          map((profileData) => {
            this.userService.isFirstAccess$.next(false);
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            this.systemNotificationsService.openSuccessSnackBar(EUserProfileActions.UpdateUserInfoSuccess);
            return new UpdateProfileDataSuccessAction(profileData);
          }),
          catchError((error) => {
            console.log('updateProfileData$', error);
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            this.systemNotificationsService.openErrorSnackBar(error?.error?.error);
            return of(new UpdateProfileDataErrorAction(error));
          })
        );
    })
  ));

  updateProfilePhoto$ = createEffect(() => this.actions$.pipe(
    ofType(profileActionsType.updateProfilePicture),
    switchMap(({ data }) => {
      return this.userService.updateProfilePhoto(data)
        .pipe(
          map((data) => {
            localStorage.setItem(STORAGE_ITEMS.PHOTO_CROP, data.photo);
            this.systemNotificationsService.openSuccessSnackBar(EUserProfileActions.UserPhotoChangeSuccess);
            this.dialog.closeAll();
            this.router.navigate(['/user-profile/main']);
            return new UpdateProfilePictureSuccessAction(data);
          }),
          catchError((error) => {
            console.log('updateProfilePhoto$', error);
            this.systemNotificationsService.openErrorSnackBar(error?.error?.error);
            return of(new UpdateProfilePictureErrorAction(data));
          })
        );
    })
  ));

  deleteProfileData$ = createEffect(() => this.actions$.pipe(
    ofType(profileActionsType.deleteProfileData),
    switchMap(({ password }) => {
      return this.userService.deleteProfile(password)
        .pipe(
          map((profileData) => {
            CLocalStorageDefaultKeys.forEach((key: string) => localStorage.removeItem(key));
            localStorage.setItem('link_type', 'WEB');
            this.dialog.closeAll();
            this.router.navigate(['']);
            this.systemNotificationsService.openSuccessSnackBar(EUserProfileActions.DeleteUserProfile);
            this.store$.dispatch(new ClearSingleServiceStateAction());
            this.store$.dispatch(new ClearPaymentCardsAction());
            return new ClearProfileDataAction();
          }),
          catchError((error) => {
            console.log('deleteProfileData$', error);
            this.systemNotificationsService.openErrorSnackBar(error?.error?.error);
            return of(new DeleteProfileDataErrorAction(error));
          })
        );
    })
  ));

  deleteProfilePicture$ = createEffect(() => this.actions$.pipe(
    ofType(profileActionsType.deleteProfilePicture),
    switchMap(() => {
      return this.userService.deleteProfilePhoto()
        .pipe(
          map(() => {
            this.dialog.closeAll();
            this.systemNotificationsService.openSuccessSnackBar(EUserProfileActions.DeleteUserProfilePicture);
            return new DeleteProfilePictureSuccessAction();
          }),
          catchError((error) => {
            console.log('deleteProfilePicture$', error);
            this.systemNotificationsService.openErrorSnackBar(error?.error?.error);
            return of(new DeleteProfilePictureErrorAction(error));
          })
        );
    })
  ));
}
