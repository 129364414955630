import { Action } from '@ngrx/store';
import { IDevicesStateData, ISessionStateData, IWorkerStateData } from './session.reducer';

export enum sessionActionsType {
  setSessionParams = '[Session] Set session params',
  toggleMirrorState = '[Session] Toggle mirror state',
  toggleChatState = '[Session] Toggle chat state',
  setDevicesState = '[Session] Set devices state',
  setNotificationMessageState = '[Session] Set notification message state',
  changeDevicesState = '[Session] Change devices state',
  setWorkerState = '[Session] Set worker state',
  clearWorkerState = '[Session] Clear worker state',
}

export class SetSessionParamsAction implements Action {
  readonly type = sessionActionsType.setSessionParams;

  constructor(public session: ISessionStateData) {
  }
}

export class ToggleMirrorStateAction implements Action {
  readonly type = sessionActionsType.toggleMirrorState;
}

export class ToggleChatStateAction implements Action {
  readonly type = sessionActionsType.toggleChatState;
}

export class SetDevicesStateAction implements Action {
  readonly type = sessionActionsType.setDevicesState;

  constructor(public devices: IDevicesStateData) {
  }
}

export class SetNotificationMessageAction implements Action {
  readonly type = sessionActionsType.setNotificationMessageState;

  constructor(public state: boolean) {
  }
}

export class ChangeDevicesStateAction implements Action {
  readonly type = sessionActionsType.changeDevicesState;

  constructor(public devices: {
    audio?: boolean,
    video?: boolean,
  }) {
  }
}

export class SetWorkerStateAction implements Action {
  readonly type = sessionActionsType.setWorkerState;

  constructor(public worker: IWorkerStateData) {
  }
}

export class ClearWorkerStateAction implements Action {
  readonly type = sessionActionsType.clearWorkerState;
}

export type SessionActions = SetSessionParamsAction |
  SetDevicesStateAction |
  SetWorkerStateAction |
  ClearWorkerStateAction |
  ChangeDevicesStateAction |
  ToggleMirrorStateAction |
  ToggleChatStateAction |
  SetNotificationMessageAction;
