import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

/**
 * Socket.io instances
 */
import { io, Socket } from "socket.io-client";

/**
 * Models
 */
import { EWebSocketEvent } from '@services/web-sockets/web-socket.enums';
import {
  CWebSocketConfig,
  CWebSocketPingInterval,
  CWebSocketPongWaitingInterval
} from '@services/web-sockets/web-socket.config';
import { environment } from '@environments/environment';
import { IWebSocketDataEvent } from '@services/web-sockets/web-socket.interfaces';
import { SetSocketTokenAction } from '../../reducers/profile/profile.actions';

/**
 * A service for managing WebSocket connections with the server.
 */
@Injectable({
  providedIn: 'root'
})
export class WebSocketsService {
  /** A subject that emits when it's time to destroy the timer. */
  private destroyTimer$: Subject<void> = new Subject<void>();
  /** A subject that emits when it's time to destroy the events. */
  private destroyEvents$: Subject<void> = new Subject<void>();
  /** The WebSocket connection instance. */
  private socket$: Socket | null = null;
  /** The channel name of the user. */
  private userChannelName: string | null = null;

  /** A subject that emits messages received from the WebSocket. */
  public messagesSubject$: BehaviorSubject<IWebSocketDataEvent<any> | null> = new BehaviorSubject<IWebSocketDataEvent<any> | null>(null);
  /** A subject that emits after web-socket reconnect. */
  public reconnectSubject$: Subject<void> = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {
  }

  getSocketToken(): string | undefined {
    return this.socket$?.auth?.['token'];
  }

  /**
   * Makes a request to the server to get the user's authorization token for the web socket connection.
   */
  createConnection(): void {
    const { api_url, ws_channel_name } = environment;
    this.httpClient.get<{ socket_token: string, status: boolean }>(api_url + '/socket/auth')
      .subscribe({
        next: ({ socket_token }) => {
          this.userChannelName = ws_channel_name + socket_token;
          this.createSocket(socket_token);
        },
        error: (error) => {
          console.log('createConnection error', error);
        }
      });
  }

  /**
   * Creates a new WebSocket instance.
   * @param {string} token - The user token used for authentication.
   * @return {Socket} The WebSocket instance.
   */
  createSocket(token: string): Socket | null {
    if (this.socket$?.connected) return null;
    this.socket$ = io(environment.ws_url, {
      ...CWebSocketConfig,
      auth: { token, type: 'is_web_user' }
    });
    console.log('this.socket$', this.socket$);
    this.store.dispatch(new SetSocketTokenAction(token));
    this.subscribeForEvents();
    this.initPingEvent();
    return this.socket$;
  }

  /**
   * Destroy the WebSocket instance & unsubscribe from events.
   */
  destroySocket(): void {
    this.destroyEvents$.next();
    this.destroyTimer$.next();
    if (this.socket$) {
      this.socket$.disconnect();
    }
    this.unsubscribeFromEvents();
  }

  /**
   * Disconnects the WebSocket instance from the server.
   */
  disconnectSocket(): void {
    this.destroySocket();
    this.messagesSubject$.next(null);
  }

  /**
   * Reconnects the WebSocket instance to the server.
   */
  reconnectSocket(): void {
    this.destroySocket();
    this.socket$.connect();
    this.reconnectSubject$.next();
    this.subscribeForEvents();
    this.initPingEvent();
  }

  /**
   * Initializes the ping event to keep the WebSocket connection alive.
   */
  initPingEvent(): void {
    interval(CWebSocketPingInterval)
      .pipe(takeUntil(this.destroyEvents$))
      .subscribe({
        next: () => {
          this.socket$.emit(EWebSocketEvent.Ping);
          this.startPongWaitingTimer();
        }
      });
  }

  /**
   * Starts the pong waiting timer.
   */
  startPongWaitingTimer(): void {
    interval(CWebSocketPongWaitingInterval)
      .pipe(takeUntil(this.destroyTimer$))
      .subscribe({
        next: () => {
          this.reconnectSocket();
        }
      });
  }

  /**
   * Handles the 'Connect' event of the web socket.
   */
  onConnectEvent(): void {
    console.log('Socket successfully connected!');
  }

  /**
   * Handles incoming data events from the web socket.
   * @param {IWebSocketDataEvent} dataEvent - The data received from the web socket by user channel.
   */
  onDataEvent(dataEvent: IWebSocketDataEvent<any>): void {
    if (!Object.keys(dataEvent).length) {
      return;
    }
    this.messagesSubject$.next(dataEvent);
  }

  /**
   * Handles connect to room event from the web socket.
   * @param {string} dataEvent - The message from the web socket by user channel.
   */
  onConnectToRoomEvent(dataEvent: { type: string, data: { message: string } }): void {
    console.log('onConnectToRoomEvent', dataEvent)
  }

  /**
   * Handles the 'Pong' event of the web socket.
   */
  onPongEvent(): void {
    this.destroyTimer$.next();
  }

  /**
   * Subscribes to events of the web socket.
   */
  subscribeForEvents(): void {
    this.socket$?.on(EWebSocketEvent.Connect, this.onConnectEvent.bind(this));
    this.socket$?.on(EWebSocketEvent.ConnectToRoom, this.onConnectToRoomEvent.bind(this));
    this.socket$?.on(EWebSocketEvent.Pong, this.onPongEvent.bind(this));
    this.socket$?.on(this.userChannelName, this.onDataEvent.bind(this));
  }

  /**
   * Unsubscribes from events of the web socket.
   */
  unsubscribeFromEvents(): void {
    this.socket$?.removeListener(EWebSocketEvent.Connect, this.onConnectEvent.bind(this));
    this.socket$?.removeListener(EWebSocketEvent.ConnectToRoom, this.onConnectToRoomEvent.bind(this));
    this.socket$?.removeListener(EWebSocketEvent.Pong, this.onPongEvent.bind(this));
    this.socket$?.removeListener(this.userChannelName, this.onDataEvent.bind(this));
  }
}
