import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective,
  NG_VALUE_ACCESSOR, NgControl,
} from "@angular/forms";

// Models
import { EInputTypes } from "@shared/components/custom-input/custom-input.enums";
import { ICustomInputParameters } from "@shared/components/custom-input/custom-input.interfaces";

// Providers
export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomInputComponent),
  multi: true,
};

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  providers: [INPUT_VALUE_ACCESSOR]
})
export class CustomInputComponent implements OnInit, ControlValueAccessor {
  public readonly EInputTypes = EInputTypes;
  public componentParameters: ICustomInputParameters;
  public control: AbstractControl;

  @Input() isRequired?: boolean = false;
  @Input() label?: string = '';
  @Input() placeholder?: string = '';

  @Input() set inputParameters(data: ICustomInputParameters) {
    this.componentParameters = data;
  }

  get inputParameters(): ICustomInputParameters {
    return this.componentParameters;
  }

  public focused: boolean = false;
  public isShowPassword: boolean = false;
  public value: string | null = null;
  public disableState = false;

  private onChange: (p: any) => void = () => {
  };
  private onTouched: (p: any) => void = () => {
  };

  constructor(private injector: Injector) {
  }

  ngOnInit(): void {
    this.getControl();
  }

  getControl(): void {
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.control = this.injector.get(FormGroupDirective).getControl(ngControl);
      return;
    }
    this.control = (ngControl as FormControlDirective).form as FormControl;
  }

  toggleFocus(state: boolean): void {
    this.focused = state;
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: (p: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableState = isDisabled;
  }

  change(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  getInputType(type: string): string {
    switch (type) {
      case EInputTypes.Text:
        return 'text';
      case EInputTypes.EmailIcon:
      case EInputTypes.Email:
        return 'email';
      case EInputTypes.Password:
        return 'password';
      default:
        return 'text';
    }
  }

  getAutocompleteValue(type: string): string {
    switch (type) {
      case EInputTypes.Text:
        return 'name';
      case EInputTypes.EmailIcon:
      case EInputTypes.Email:
        return 'email';
      case EInputTypes.Password:
        return 'current-password';
      default:
        return 'name';
    }
  }

  toggleShowPassword(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  setCaretPosition(event: Event): void {
    if (this.inputParameters?.type === EInputTypes.Email) return;
    const targetInput = event.target as HTMLInputElement;
    targetInput.selectionStart = targetInput.value.length;
  }
}
