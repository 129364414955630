import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// Services
import { SystemNotificationsService } from '@services/system-notifications.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
  public messageGroup: FormGroup;
  private _isBlocked: boolean = null;

  @Input() blockedMessage: string = '';
  @Input() set isBlocked(state: boolean) {
    this._isBlocked = state;
    this.changeFormDisableState(state);
  };

  get isBlocked(): boolean {
    return this._isBlocked;
  }

  @Output() uploadedFile: EventEmitter<File> = new EventEmitter<File>(null);
  @Output() sendMessage: EventEmitter<string> = new EventEmitter<string>();

  get placeholder(): string {
    return this.isBlocked ? 'type_blocked' : 'type_message';
  }

  constructor(
    private fb: FormBuilder,
    private systemNotificationsService: SystemNotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.messageGroup = this.fb.group({
      message: [{ value: '', disabled: true }],
      file: [{ value: null, disabled: true }]
    })
    if (!this.isBlocked) {
      this.messageGroup?.get('message')?.enable();
      this.messageGroup?.get('file')?.enable();
    }
  }

  changeFormDisableState(state: boolean): void {
    if (!this.messageGroup) return;
    if (state) {
      this.messageGroup?.get('message')?.disable();
      this.messageGroup?.get('file')?.disable();
      return;
    }
    this.messageGroup?.get('message')?.enable();
    this.messageGroup?.get('file')?.enable();
  }

  keyBoardHandler(event: KeyboardEvent): void {
    if (event.code !== 'Enter' || event.code === 'Enter' && event.shiftKey) return;
    this.sendMessageHandler();
  }

  sendMessageHandler(): void {
    if (!this.messageGroup.value.message.trim().length) {
      this.messageGroup.get('message').setValue('');
      return;
    }
    this.sendMessage.next(this.messageGroup.value.message);
    this.messageGroup.reset();
  }

  uploadFile(event: Event): void {
    const inputTarget = (event.target as HTMLInputElement);
    if (inputTarget.files[0].size > 20000000) {
      this.systemNotificationsService.openErrorSnackBar('File size must be less than 20 megabytes');
      return;
    }
    this.uploadedFile.next(inputTarget.files[0]);
    inputTarget.value = null;
  }
}
