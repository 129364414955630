<div class="custom-select-value" (click)="openSelect()" appNotTarget (clickOutside)="clickOutside()" [class.empty-list]="!options?.length">
  <div class="value">
    {{ value }}
  </div>
  <div class="icon-arrow-down" [class.active]="active">
    <img src="/assets/images/svg/arrow-dropdown.svg" alt="arrow-dropdown">
  </div>

  <div class="custom-select-options" [class.active]="active">
    <ng-container *ngIf="options?.length">
      <div class="option" *ngFor="let option of options; index as i" (click)="change(option.label)">
        {{ option?.label !== null ? (option?.label?.length >= 30 ? option.label.slice(0, 30) + '...' : option?.label) : 'Device № ' + i }}
      </div>
    </ng-container>
    <ng-container *ngIf="!options.length">
      <div class="option">
        {{ "device_settings.no_device_detected" | translate }}
      </div>
    </ng-container>
  </div>
</div>
