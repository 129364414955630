import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@ngrx/store';

import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from 'rxjs/operators';

// Services
import { InternetConnectionService } from '@services/internet-connection.service';

// Models
import {
  IChatMsg,
  IRejectCall,
  ITempCall,
  ITempResp
} from "@interfaces/temporary-user.interface";
import { ICategoriesResp } from "@interfaces/categories.interface";
import { ICard, ICards, ITenantApprove } from "@interfaces/user.interface";
import { ICallData, IChatHistory } from "@interfaces/chat-history.interface";
import { ESessionStorageKeys, STORAGE_ITEMS } from "@consts/storage";

// Helpers
import { getQuery } from "@helpers/services-helper";
import { RoleHelper } from "@helpers/role-helper";

const USER = '/users/'
const TEMP_USER = '/temporary/'

@Injectable({
  providedIn: 'root'
})
export class UserCallsService {

  constructor(
    private http: HttpClient,
    private store: Store,
    private internetConnectionService: InternetConnectionService,
    private deviceDetectorService: DeviceDetectorService
  ) {
  }

  public setUserType(): string {
    return RoleHelper.isTemporary() ? TEMP_USER : USER
  }

  public userGetServices(): Observable<ICategoriesResp> {
    return this.http.get<ICategoriesResp>(`${ this.setUserType() }get-services`);
  }

  public userGetCards(): Observable<ICards> {
    return this.http.get<ICards>(`${ USER }stripe-cards`);
  }

  public updateUserCardSelected(card_id: string): Observable<ICards> {
    return this.http.post<ICards>(`${ USER }stripe-select-card`, { card_id });
  }

  public deleteUserCard(card_id: string): Observable<ICards> {
    return this.http.request<ICards>('delete', `${ USER }stripe-delete-card`, {
      body: { card_id }
    });
  }

  public userCreateCard(data): Observable<ICard> {
    return this.http.post<ICard>(`${ this.setUserType() }stripe-create-card`, data);
  }

  public userCreateCall(data, isRedirectCall: boolean = false): Observable<ITempCall> {
    localStorage.setItem(STORAGE_ITEMS.CATEGORY, data.service_id);

    let newData;
    const deeplink_token = localStorage.getItem('deeplink_token');
    if (deeplink_token) {
      newData = { ...data, deeplink_token };
    } else {
      newData = data;
    }
    return this.internetConnectionService.checkInternetConnection()
      .pipe(
        catchError(error => throwError(error)),
        switchMap((connectionData) => {
          const { browser, browser_version, os, os_version } = this.deviceDetectorService;
          const is_terms_accepted = sessionStorage.getItem(ESessionStorageKeys.TermsAccepted) === 'true';
          return this.http.post<ITempCall>(`${ this.setUserType() }create-call`, {
            ...newData,
            speed: connectionData.speed,
            browser: browser + ' | ' + browser_version,
            os: os + ' | ' + os_version,
            is_terms_accepted: isRedirectCall ? isRedirectCall : is_terms_accepted
          });
        })
      );
  }

  public userPayment(data): Observable<ITempResp> {
    return this.http.post<ITempResp>(`${ this.setUserType() }payment-intent`, data);
  }

  public userDeleteLastPayment(data): Observable<ITempResp> {
    return this.http.post<ITempResp>(`${ this.setUserType() }delete-last-payment`, data);
  }

  public userApprovePayment(data): Observable<ITempResp> {
    return this.http.post<ITempResp>(`${ this.setUserType() }approve-payment-intent`, data);
  }

  public userStartCall(data): Observable<ITempCall> {
    const newData = {
      ...data,
      appVersion: navigator?.appVersion,
      userAgent: navigator?.userAgent,
      supportedConstraints: navigator.mediaDevices?.getSupportedConstraints(),
      browser: this.getUserBrowser(),
      speed: navigator['connection']?.downlink + ' Mb/s',
      windowSizes: {
        width: window?.innerWidth,
        height: window?.innerHeight
      }
    }
    return this.http.post<ITempCall>(`${ this.setUserType() }start-call`, newData);
  }

  sendErrorCall(errorBody: any): void {
    this.http.post('/log', { error: errorBody }).subscribe();
  }

  sendCallFeedback(body: any): Observable<any> {
    return this.http.post('/users/send-call-feedback', body);
  }

  public userRejectCall(data): Observable<IRejectCall> {
    return this.http.post<IRejectCall>(`${ this.setUserType() }reject-call`, data);
  }

  public userChatMsg(data): Observable<IChatMsg> {
    return this.http.post<IChatMsg>(`${ this.setUserType() }chat-message`, data);
  }

  public markAsRead(data: { call_id: number, device_UDID?: string }): Observable<any> {
    return this.http.post<any>(`${ this.setUserType() }mark-as-read`, data);
  }

  public userCalls(data): Observable<IChatHistory> {
    return this.http.get<IChatHistory>(`${ USER }calls?${ getQuery(data) }`);
  }

  public userCallWithChat(data): Observable<ICallData> {
    return this.http.get<ICallData>(`${ this.setUserType() }call-info-with-chat?${ getQuery(data) }`);
  }

  public userCancelSockets(data): Observable<ITempResp> {
    return this.http.post<ITempResp>(`${ this.setUserType() }cancel-sockets-extra-charge`, data);
  }

  public userCallPermissionRequest(data): Observable<ITenantApprove> {
    return this.http.post<ITenantApprove>(`${ USER }call-permission-request`, data);
  }

  public userCallPermissionRequestStatus(data): Observable<ITenantApprove> {
    return this.http.post<ITenantApprove>(`${ USER }call-permission-request-status?${ getQuery(data) }`, {});
  }

  getUserBrowser(): string {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    }
    if (navigator?.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    }
    if (navigator?.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    }
    if (navigator?.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    }
    if ((navigator?.userAgent.indexOf("MSIE") != -1) || (!!document['documentMode'] == true)) {
      return 'IE';  //IF IE > 10
    }
    return 'unknown';
  }
}

