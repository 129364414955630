import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';

/**
 * Player Library https://videogular.github.io/ngx-videogular/docs/
 */

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent {

  @Input() public sources: string[] = [];
  @Input() public type: string = 'video/mp4';
  @Input() isThumbnail: boolean = false;

  @Output() onErrorLoad: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isErrorLoad: boolean = false;

  constructor(private vgApiService: VgApiService) {
  }

  onPlayerReady(api: VgApiService): void {
    this.vgApiService = api;
  }

  onError(error: any): void {
    this.isErrorLoad = true;
    this.onErrorLoad.emit(true);
  }
}
