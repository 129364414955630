/**
 * An enumeration that defines WebSocket events.
 *
 * @readonly
 * @enum {string}
 */
export enum EWebSocketEvent {
  /**
   * The "connect" event.
   */
  Connect = 'connect',

  /**
   * The "ping" event.
   */
  Ping = 'ping',

  /**
   * The "pong" event.
   */
  Pong = 'pong',

  /**
   * The "connectToRoom" event.
   */
  ConnectToRoom = 'connectToRoom'
}
