export const svgIcons = [
  {name: 'rolling',path: 'assets/images/svg/rolling.svg'},
  {name: 'add-photo',path: 'assets/images/svg/add_photo.svg'},
  {name: 'audio',path: 'assets/images/svg/audio.svg'},
  {name: 'audio-item-fill',path: 'assets/images/svg/audio-item-fill.svg'},
  {name: 'audio-item-empty',path: 'assets/images/svg/audio-item-empty.svg'},
  {name: 'audio-off',path: 'assets/images/svg/audio_off.svg'},
  {name: 'attach',path: 'assets/images/svg/attach.svg'},
  {name: 'arrow-dropdown',path: 'assets/images/svg/arrow-dropdown.svg'},
  {name: 'american-express',path: 'assets/images/svg/stripe-icons/american express.svg'},
  {name: 'chat-icon',path: 'assets/images/svg/chat_icon.svg'},
  {name: 'close',path: 'assets/images/svg/close.svg'},
  {name: 'check-email',path: 'assets/images/svg/check_email.svg'},
  {name: 'info',path: 'assets/images/svg/info.svg'},
  {name: 'chevron',path: 'assets/images/svg/chevron.svg'},
  {name: 'delete',path: 'assets/images/svg/delete.svg'},
  {name: 'default-user',path: 'assets/images/default-photo.svg'},
  {name: 'dinners',path: 'assets/images/svg/stripe-icons/dinners club.svg'},
  {name: 'double-check',path: 'assets/images/svg/double_check.svg'},
  {name: 'email-confirmation',path: 'assets/images/svg/email_confirmation.svg'},
  {name: 'edit',path: 'assets/images/svg/edit.svg'},
  {name: 'end-call',path: 'assets/images/svg/end_call.svg'},
  {name: 'eye',path: 'assets/images/svg/eye.svg'},
  {name: 'eye-close',path: 'assets/images/svg/eye-close.svg'},
  {name: 'empty-card',path: 'assets/images/svg/empty-card.svg'},
  {name: 'error',path: 'assets/images/svg/error.svg'},
  {name: 'front-camera',path: 'assets/images/svg/front_camera.svg'},
  {name: 'switch-camera',path: 'assets/images/svg/switch-camera.svg'},
  {name: 'ok',path: 'assets/images/svg/ok.svg'},
  {name: 'bad',path: 'assets/images/svg/bad.svg'},
  {name: 'excellent',path: 'assets/images/svg/excellent.svg'},
  {name: 'file',path: 'assets/images/svg/file.svg'},
  {name: 'gas',path: 'assets/images/svg/gas.svg'},
  {name: 'heater',path: 'assets/images/svg/heater.svg'},
  {name: 'play',path: 'assets/images/svg/play.svg'},
  {name: 'click-photo',path: 'assets/images/svg/click-photo.svg'},
  {name: 'stop',path: 'assets/images/svg/stop.svg'},
  {name: 'loading-logo',path: 'assets/images/svg/loading_logo.svg'},
  {name: 'lock',path: 'assets/images/svg/lock.svg'},
  {name: 'logout', path: 'assets/images/svg/logout.svg'},
  {name: 'mail',path: 'assets/images/svg/mail.svg'},
  {name: 'main-logo',path: 'assets/images/svg/main_logo.svg'},
  {name: 'main-logo-tablet',path: 'assets/images/svg/tablet_logo.svg'},
  {name: 'main-logo-small',path: 'assets/images/svg/main_logo_small.svg'},
  {name: 'mastercard',path: 'assets/images/svg/stripe-icons/mastercard.svg'},
  {name: 'menu-burger',path: 'assets/images/svg/menu_burger.svg'},
  {name: 'menu-vertical',path: 'assets/images/svg/menu_vertical.svg'},
  {name: 'micro',path: 'assets/images/svg/microphone.svg'},
  {name: 'micro-off',path: 'assets/images/svg/microphone_off.svg'},
  {name: 'no-call-history',path: 'assets/images/svg/no_call_history.svg'},
  {name: 'plumber',path: 'assets/images/svg/plumber.svg'},
  {name: 'plumbing',path: 'assets/images/svg/plumbing.svg'},
  {name: 'plus',path: 'assets/images/svg/plus.svg'},
  {name: 'phone',path: 'assets/images/svg/phone.svg'},
  {name: 'send',path: 'assets/images/svg/send.svg'},
  {name: 'select',path: 'assets/images/svg/select.svg'},
  {name: 'success',path: 'assets/images/svg/success.svg'},
  {name: 'solved-problem',path: 'assets/images/svg/solved_problem.svg'},
  {name: 'warning',path: 'assets/images/svg/warning.svg'},
  {name: 'check',path: 'assets/images/svg/single_check.svg'},
  {name: 'user-photo',path: 'assets/images/svg/user_photo.svg'},
  {name: 'video',path: 'assets/images/svg/video.svg'},
  {name: 'video-off',path: 'assets/images/svg/video_off.svg'},
  {name: 'visa',path: 'assets/images/svg/stripe-icons/visa.svg'},
  {name: 'zoom-minus',path: 'assets/images/svg/zoom-minus.svg'},
  {name: 'zoom-plus',path: 'assets/images/svg/zoom-plus.svg'},
  {name: 'check-double',path: 'assets/images/svg/check-double.svg'},
  {name: 'check-one',path: 'assets/images/svg/check-one.svg'},
  {name: 'rotate-left',path: 'assets/images/svg/rotate-left.svg'},
  {name: 'rotate-right',path: 'assets/images/svg/rotate-right.svg'},
  {name: 'mono-rotate',path: 'assets/images/svg/mono-rotate-cw.svg'},
]

