import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// Actions
import { ToggleLoaderStateAction } from '../../../reducers/loader/loader.actions';

// Services
import { ResizeDetectionService } from "@services/resize-detection.service";
import { UserService } from "@services/user.service";
import { WidgetsService } from '@services/widgets.service';

// Models
import { RoleHelper } from "@helpers/role-helper";
import { CLanguagesList } from '@shared/components/language-select/language-select.constantes';
import { CHeaderConfigList, CSubmenu } from '@shared/components/header/header.constantes';
import { ESessionStorageKeys } from '@consts/storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  public readonly configList = CHeaderConfigList;
  public languageGroup: FormGroup;

  @ViewChild('menuContainer') menuContainer;
  @ViewChild('menuButton') menuButton;

  public destroy$: Subject<void> = new Subject<void>();
  public screenSize$: BehaviorSubject<string> = this.resize.screenSize;

  public emailNeedUpdate: boolean;
  public submenu = CSubmenu;
  public showHeader = true;
  public openedSubmenu = false;
  public isTemporary: boolean = true;

  get isSafari(): boolean {
    return this.deviceDetectorService.browser === 'Safari';
  }

  get isWidget(): boolean {
    return this.widgetsService.getWidgetStatus();
  }

  constructor(
    private userService: UserService,
    private widgetsService: WidgetsService,
    private store: Store,
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private fb: FormBuilder,
    private resize: ResizeDetectionService
  ) {
  }

  public openSubmenu(): void {
    this.openedSubmenu = !this.openedSubmenu;
  }

  logoClickHandler(): void {
    sessionStorage.removeItem(ESessionStorageKeys.TermsAccepted);
  }

  loaderAction(state: boolean, target: any): void {
    if (!state || (target as HTMLElement).classList.contains('active-link')) return;
    this.store.dispatch(new ToggleLoaderStateAction(true));
  }

  ngOnInit(): void {
    this.showHeader = !RoleHelper.isTemporary();
    this.isTemporary = RoleHelper.isTemporary();
    this.initForm();
    this.emailNeedUpdate = this.userService.getUser()?.need_update_email;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleBurgerMenu(): void {
    this.openedSubmenu = !this.openedSubmenu;
  }

  initForm(): void {
    this.languageGroup = this.fb.group({
      language: ['']
    });
    this.detectChangeLanguage();
  }

  detectChangeLanguage(): void {
    this.languageGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          const currentKey = CLanguagesList.find(languageData => languageData.language === data.language).key;
          console.log('detectChangeLanguage', currentKey);
          this.changeLanguage(currentKey);
        }
      })
  }

  changeLanguage(language: string = 'en'): void {
    this.translateService.use(language)
      .pipe(first())
      .subscribe({
          next: (languageData) => {
            console.log('use', languageData);
            localStorage.setItem('currentLanguage', language);
            window.location.reload();
          }
        }
      );
  }
}
