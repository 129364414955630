import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type TVideoPreviewDialogData = {
  video: {
    name: string,
    url: string,
    type: string
  },
  isMobile: boolean
}

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss']
})
export class VideoPreviewComponent {

  public previewData: TVideoPreviewDialogData['video'];
  public isMobile: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TVideoPreviewDialogData,
    private dialogRef: MatDialogRef<VideoPreviewComponent>
  ) {
    this.previewData = data.video;
    this.isMobile = data.isMobile;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
