<div class="chat-body" [style]="{ height: innerHeightBody }" #messageBodyContainer>
  <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loader"></ng-container>

  <ng-container *ngFor="let messageData of messagesList">

    <div *ngIf="!isLoading" class="chat-body-item" [ngClass]="{
        'my-message' : messageData.sender_id === userId,
        'other-message': messageData.sender_id !== userId
    }">

      <app-message-item [userId]="userId" [message]="messageData"></app-message-item>

    </div>

  </ng-container>

</div>

<app-chat-message *ngIf="!isOnlyBody"
                  [isBlocked]="isBlocked"
                  [blockedMessage]="blockedMessage"
                  (uploadedFile)="sendFile($any($event))"
                  (sendMessage)="sendMessage($event)">
</app-chat-message>

<ng-template #loader>
  <div class="action-loader lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
