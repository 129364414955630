import { EButtonColorTypes, EButtonTypes } from '@shared/components/button/button.enums';

export const CHeaderConfigList = {
  buttons: {
    login: {
      type: EButtonTypes.Small,
      colorType: EButtonColorTypes.Empty
    },
    signUp: {
      type: EButtonTypes.Small,
      colorType: EButtonColorTypes.Fill
    },
    engineer: {
      type: EButtonTypes.Small,
      colorType: EButtonColorTypes.Fill
    }
  }
}
export const CSubmenu = [
  {
    path: '/home-page/main-page',
    title: 'Home',
    loader: false
  },
  {
    path: '/user-profile/main',
    title: 'Profile',
    loader: true
  },
  {
    path: '/call/call-history',
    title: 'Call history',
    loader: false
  },
  {
    path: '/privacy',
    title: 'Privacy Policy',
    loader: false
  },
  {
    path: '/terms',
    title: 'Terms of Service',
    loader: false
  },
];
