export enum EButtonTypes {
  Small = 'small',
  Medium = 'medium',
  LogOut = 'log-out'
}

export enum EButtonColorTypes {
  Fill = 'fill',
  Empty = 'empty'
}
