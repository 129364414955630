import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Device } from "@opentok/client";

export const SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [SELECT_VALUE_ACCESSOR]
})
export class CustomSelectComponent implements ControlValueAccessor {
  public options: Device[];
  public active: boolean = false;

  @Output() changeValue?: EventEmitter<string> = new EventEmitter<string>();

  @Input() set optionList(list: Device[]) {
    this.options = list;
  }

  get optionList(): Device[] {
    return this.options;
  }

  public value: string | null = null;
  public disableState = false;
  private onChange: (p: any) => void = () => {
  };
  private onTouched: (p: any) => void = () => {
  };

  clickOutside(): void{
    this.active = false;
  }

  openSelect(): void {
    this.active = !this.active;
  }

  writeValue(value: string | null): void {
    if (this.options.length) {
      this.value = value !== null ?
        (value?.length >= 30 ? value?.slice(0, 30) + '...' : value)
        : 'Device № 0';
    } else {
      this.value = 'No device detected';
    }
  }

  registerOnChange(fn: (p: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableState = isDisabled;
  }

  change(value: string): void {
    this.changeValue.emit(value);
    this.value = value;
    this.onChange(value);
    this.onTouched(value);
  }
}
