import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type TImagePreviewData = {
  image: {
    name: string,
    url: string
  },
  isMobile: boolean
};

export enum EDirectionImage {
  Left = 'left',
  Right = 'right',
}

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {
  public readonly EDirectionImage = EDirectionImage;
  public rotateDeg = 0;

  public previewImage: TImagePreviewData['image'];
  public isMobile: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TImagePreviewData,
    private dialogRef: MatDialogRef<ImagePreviewComponent>
  ) {
    this.previewImage = data.image;
    this.isMobile = data.isMobile;
  }

  /**
   Rotates an image element in the given direction and scales it based on its orientation
   @param {HTMLImageElement} imageElement - The image element to rotate
   @param {string} direction - The direction to rotate the image ("right" or "left")
   @return {void}
   */
  rotateImage(imageElement: HTMLImageElement, direction: string): void {
    /**
     Determines if the image is horizontal (width > height)
     @type {boolean}
     */
    const isHorizontal = imageElement.width > imageElement.height;
    /**
     The degrees to rotate the image
     @type {number}
     */
    this.rotateDeg = direction === EDirectionImage.Right ? this.rotateDeg + 90 : this.rotateDeg - 90;
    /**
     The scale factor to apply to the image
     @type {number}
     */
    let scale = isHorizontal ? (this.isMobile ? 1.2 : 0.6) : 1;
    // Adjust scale factor based on image orientation and rotation degree
    if (this.rotateDeg % 180 === 0 && isHorizontal) {
      scale = isHorizontal ? 1 : 0.6;
    }

    if (this.rotateDeg % 180 !== 0 && !isHorizontal) {
      scale = isHorizontal ? 1 : 0.6;
    }

    /**
     Applies the rotation and scale transform to the image element
     */
    imageElement.style.transform = `rotate(${ this.rotateDeg }deg) scale(${ scale })`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
