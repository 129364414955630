<div class="custom-input" [ngClass]="{
  'focused': focused,
  'icon-input-wrapper': inputParameters?.icon
  }"
     [class.invalid]="!control?.valid && control?.touched && value || !control?.valid && control?.touched">
  <div class="custom-input-label" *ngIf="!inputParameters?.icon">
    <label>{{ label }}</label>
    <span *ngIf="isRequired" matTooltipPosition="above" matTooltip="This field is required"
          class="required-icon">*</span>
  </div>
  <div class="custom-input-item"
       [class.icon-input]="inputParameters?.icon">

    <div class="custom-input-item-icon" *ngIf="inputParameters?.icon">
      <mat-icon [svgIcon]="inputParameters?.icon"></mat-icon>
    </div>

    <div class="custom-input-item-icon-password" *ngIf="inputParameters.type === EInputTypes.Password"
         (click)="toggleShowPassword()">
      <mat-icon [svgIcon]="isShowPassword ? 'eye' : 'eye-close'"></mat-icon>
    </div>

    <input [type]="isShowPassword ? 'text' : getInputType(inputParameters.type)"
           [placeholder]="placeholder"
           [value]="value"
           [autocomplete]="getAutocompleteValue(inputParameters.type)"
           (focusin)="toggleFocus(true)"
           (focusout)="toggleFocus(false)"
           (input)="change($event)">
<!--           (click)="setCaretPosition($event)">-->
  </div>
</div>


