import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';

// Services
import { UserCallsService } from '@services/user-calls.service';
import { WebSocketsService } from '@services/web-sockets/web-sockets.service';
import { SystemNotificationsService } from '@services/system-notifications.service';

// Helpers
import { RoleHelper } from '@helpers/role-helper';

// Models
import { STORAGE_ITEMS } from '@consts/storage';
import { IChatMsg } from '@interfaces/temporary-user.interface';
import { EChatMessageStatus } from '../../../enums/global.enums';
import { ICallData } from '@interfaces/chat-history.interface';

@Component({
  selector: 'app-chat-body',
  templateUrl: './chat-body.component.html',
  styleUrls: ['./chat-body.component.scss']
})
export class ChatBodyComponent implements OnInit, AfterViewInit, OnDestroy {
  private _isChatOpened: boolean = false;
  private _call_id: number;

  @Input() set call_id(id: number) {
    if (this._call_id) {
      this.isLoading = true;
      this._call_id = id;
      this.getChatHistory();
    }
    this._call_id = id;
  };

  get call_id(): number {
    return this._call_id;
  }

  @Input() userId: number;
  @Input() userType: string;
  @Input() isOnlyBody: boolean = false;

  @Input() set isChatOpened(value: boolean) {
    const prev = this._isChatOpened;
    this._isChatOpened = value;
    if (!prev && !!value) {
      this.markAsRead();
      this.scrollBodyToBottom();
    }
  };

  get isChatOpened(): boolean {
    return this._isChatOpened;
  }

  @Output() onChatData: EventEmitter<{
    error?: string,
    chatData?: ICallData
  }> = new EventEmitter<{ error?: string, chatData?: ICallData }>();

  @ViewChild('messageBodyContainer') messageBodyContainer: ElementRef<HTMLDivElement>;

  public destroy$: Subject<void> = new Subject<void>();
  public scrollBottom$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public messagesList: IChatMsg[] = [];
  public isLoading: boolean = true;
  public isFirstRender: boolean = true;
  public isBlocked: boolean = true;
  public blockedMessage: string = '';

  get innerHeightBody(): string {
    return (window.innerHeight - 71 - 65 - 113) + 'px';
  }

  constructor(
    private webSocketsService: WebSocketsService,
    private systemNotificationsService: SystemNotificationsService,
    private dialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService,
    private userCallService: UserCallsService,
  ) {
  }

  ngOnInit(): void {
    this.scrollBottomDetected();
    this.getChatHistory();
    this.webSockets();
    this.checkReconnectWebSocket();
  }

  ngAfterViewInit(): void {
    if (this.isFirstRender) {
      this.scrollBottom$.next(true);
      this.isFirstRender = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkReconnectWebSocket(): void {
    this.webSocketsService.reconnectSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getChatHistory();
        }
      });
  }

  scrollBottomDetected(): void {
    this.scrollBottom$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (state) => {
          if (!state) return;
          this.scrollBodyToBottom();
        }
      })
  }

  scrollBodyToBottom(): void {
    const timerId = setTimeout(() => {
      this.messageBodyContainer.nativeElement.scrollTo({
        behavior: 'smooth',
        top: this.messageBodyContainer.nativeElement.scrollHeight
      });
      clearTimeout(timerId);
    }, 500);
  }

  getChatHistory(): void {
    const data = { call_id: this.call_id };
    if (RoleHelper.isTemporary()) {
      data['device_UDID'] = localStorage.getItem(STORAGE_ITEMS.UDID);
    }
    this.userCallService.userCallWithChat(data)
      .subscribe({
        next: (chatData) => {
          const isReadOnly = chatData.is_chat_read_only;
          this.isBlocked = isReadOnly;
          this.blockedMessage = isReadOnly ? 'Sorry, the chat session has expired' : '';
          this.onChatData.emit({ chatData });
          if (this.messagesList.length === chatData.chat_history.length) {
            this.isLoading = false;
            return;
          }
          this.messagesList = chatData.chat_history.map((message) => ({ ...message, isLoad: true }));
          this.isLoading = false;
          this.scrollBottom$.next(true);
        },
        error: (error) => {
          console.log('getChatHistory error', error);
          this.isLoading = false;
          this.onChatData.emit({ error: error.error.error || error.error.message });
        }
      });
  }

  markAsRead(): void {
    if (!this.isChatOpened || !this.call_id) return;
    const data = { call_id: this.call_id };
    if (RoleHelper.isTemporary()) {
      data['device_UDID'] = localStorage.getItem(STORAGE_ITEMS.UDID);
    }
    this.userCallService.markAsRead(data)
      .subscribe({
        next: (resp) => {
          console.log('markAsRead', resp);
          this.messagesList.forEach((message) => {
            message.message_status = EChatMessageStatus.Read;
          });
        },
        error: (error) => {
          console.log('markAsRead error', error);
        },
      })
  }

  sendMessage(message: string): void {
    const data = { call_id: this.call_id, message };
    if (RoleHelper.isTemporary()) {
      data['device_UDID'] = localStorage.getItem(STORAGE_ITEMS.UDID);
    }
    this.userCallService.userChatMsg(data)
      .subscribe({
        next: (sendResponse) => {
          this.messagesList.push({ ...sendResponse, message_status: EChatMessageStatus.Sent });
          this.scrollBottom$.next(true);
        },
        error: (error) => {
          this.systemNotificationsService.openErrorSnackBar('Server error. Please try again');
          console.log('sendMessage error', error);
        }
      });
  }

  sendFile(file: File): void {
    console.log('file', file)
    const uniqueId = Math.random().toString(16).slice(2);
    this.messagesList.push({
      uniqueId,
      call_id: this.call_id,
      message_time: '',
      message_status: EChatMessageStatus.Sent,
      recipient_id: null,
      sender_id: this.userId,
      attachment: {
        name: file.name,
        size: file.size.toString(),
        type: file.type.includes('video') ? 'video' : 'image',
        url: ''
      },
      isLoad: false
    });
    const data = new FormData();
    data.set('call_id', this.call_id.toString());
    data.set('attachment', file);
    if (RoleHelper.isTemporary()) {
      data.set('device_UDID', localStorage.getItem(STORAGE_ITEMS.UDID));
    }
    this.userCallService.userChatMsg(data)
      .subscribe({
        next: (uploadResponse) => {
          this.messagesList = this.messagesList.map(message => {
            if (message.uniqueId !== uniqueId) return message;
            return {
              ...message,
              message_time: uploadResponse.message_time,
              attachment: { ...message.attachment, ...uploadResponse.attachment },
              isLoad: true
            };
          });
          this.scrollBottom$.next(true);
        },
        error: (error) => {
          this.systemNotificationsService.openErrorSnackBar(error.error.error);
          this.messagesList = this.messagesList.filter(message => message.uniqueId !== uniqueId);
        }
      });
  }

  webSockets(): void {
    this.webSocketsService.messagesSubject$
      .pipe(takeUntil(this.destroy$), filter(val => !!val))
      .subscribe({
          next: (data) => {
            if (!data) return;
            switch (data.type) {
              case 'chat-message':
                this.pushMessage(data.data);
                break;
              case 'chat-message-read':
                this.readMessages(data.data);
                break;
            }
          },
          error: (error) => {
            console.log('webSockets error', error);
            this.userCallService.sendErrorCall(error)
          }
        }
      );
  }

  readMessages(data: { messages_id: number[], call_id: number, message_status: string }): void {
    if (!this.isChatOpened) return;
    setTimeout(() => {
      if (data.call_id !== this.call_id) return;
      this.messagesList.forEach((message) => {
        message.message_status = EChatMessageStatus.Read;
      });
    }, 1000);
  }

  pushMessage(messageData: IChatMsg): void {
    if (messageData.call_id !== this.call_id) return;
    if (this.messagesList.some(m => m.message_id === messageData.message_id)) {
      return;
    }
    if (!!messageData.message) {
      this.messagesList.push(messageData);
      this.scrollBottom$.next(true);
      this.markAsRead();
      return;
    }
    const fileBody = {
      attachment: {
        name: messageData['attachment_name'],
        type: messageData['attachment_type'],
        size: messageData['attachment_size'],
        url: messageData['attachment_url']
      },
      call_id: this.call_id,
      isLoad: true,
      message: null,
      message_time: messageData.message_time,
      message_status: EChatMessageStatus.Read,
      recipient_id: messageData.sender_id,
      sender_id: messageData.sender_id,
    };
    this.messagesList.push(fileBody);
    this.scrollBottom$.next(true);
    this.markAsRead();
  }
}
