<div *ngIf="!isRTCError"
     class="modal-description modal-description-g"
     style="text-align: center;margin-bottom: 9px;">
  {{ "call.busy.permission_pop_up" | translate }}
</div>
<div *ngIf="isRTCError"
     class="modal-description modal-description-g"
     style="text-align: center;margin-bottom: 9px;">
  {{ "call.busy.rtc_error" | translate }}
</div>
<div class="modal-buttons">
  <app-button [buttonParameters]="configList.buttons.ok"
              (click)="close()">
    {{ "ok" | translate }}
  </app-button>
</div>
