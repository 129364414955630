import { IAuthResp } from '@interfaces/user.interface';
import { ProfileActions, profileActionsType } from './profile.actions';

export const profileFeatureKey = 'profile';

export interface IProfileState {
  isAuth: boolean,
  data: IAuthResp | null,
  socketToken: string | null,
  isFetching: boolean,
  isError: boolean,
}

export const initialState: IProfileState = {
  isAuth: false,
  data: null,
  socketToken: null,
  isFetching: false,
  isError: false,
};

export const profileReducer = (state = initialState, action: ProfileActions) => {
  switch (action.type) {
    case profileActionsType.setSocketToken:
      return {
        ...state,
        socketToken: action.token,
      };
    case profileActionsType.loadProfileData:
      return {
        ...state,
        isWidget: true,
        isFetching: true,
      };
    case profileActionsType.loadProfileDataSuccess:
      return {
        ...state,
        data: action.data,
        isAuth: true,
        isFetching: false,
        isError: false,
      };
    case profileActionsType.loadProfileDataError:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case profileActionsType.clearProfileData:
      return {
        isAuth: false,
        data: null,
        socketToken: null,
        isFetching: false,
        isError: false,
      };
    case profileActionsType.deleteProfilePictureSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          photo: '',
          photo_original: ''
        }
      };
    case profileActionsType.updateProfileData:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case profileActionsType.updateProfileDataSuccess:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        isError: false,
      };
    case profileActionsType.updateProfileDataError:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case profileActionsType.updateProfilePicture:
      return {
        ...state,
        isFetching: true
      };
    case profileActionsType.updateProfilePictureSuccess:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: {
          ...state.data,
          photo: action.data.photo,
          photo_original: action.data.photo_original
        }
      };
      case profileActionsType.updateProfilePictureError:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    default:
      return state;
  }
};
