import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

// Models
import { CCallModalsConfig } from '@modules/call/call-modals/call-modals.config';

@Component({
  selector: 'app-permission-popup',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionPopupComponent {
  public readonly configList = CCallModalsConfig;
  public isRTCError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PermissionPopupComponent>,
  ) {
    this.isRTCError = data?.isRTCError;
  }

  close(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    this.dialogRef.close(true);
  }
}
