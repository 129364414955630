<ng-container *ngIf="buttonParameters.type === EButtonTypes.Small">
  <button class="btn small" [disabled]="disableState || false"
          [ngClass]="{
            'small-fill': buttonParameters.colorType === EButtonColorTypes.Fill,
            'small-empty': buttonParameters.colorType === EButtonColorTypes.Empty
        }">
    <ng-container *ngTemplateOutlet="textContent"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="buttonParameters.type === EButtonTypes.Medium">
  <button class="btn medium" [disabled]="disableState || false"
          [ngClass]="{
            'medium-fill': buttonParameters.colorType === EButtonColorTypes.Fill,
            'medium-empty': buttonParameters.colorType === EButtonColorTypes.Empty
        }">
    <ng-container *ngTemplateOutlet="textContent"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="buttonParameters.type === EButtonTypes.LogOut">
  <button class="btn logout">
    <mat-icon svgIcon="logout"></mat-icon>
    <span><ng-container *ngTemplateOutlet="textContent"></ng-container></span>
  </button>
</ng-container>

<ng-template #textContent>
  <ng-content></ng-content>
</ng-template>




