<div *ngIf="isBlocked && blockedMessage" class="blocked-message">{{ blockedMessage }}</div>
<div *ngIf="!isBlocked" class="chat-message" [formGroup]="messageGroup">
  <div class="chat-message-attach">
    <mat-icon (click)="isBlocked ? $event.preventDefault() : inputFile.click()" [class.disabled-block]="isBlocked" svgIcon="attach"></mat-icon>
  </div>
  <div class="chat-message-message" #messageWrapper>
    <div class="message-text" [class.disabled-block]="isBlocked">
      <textarea formControlName="message"
                rows="2" [placeholder]="blockedMessage ? '' : 'type_message' | translate"
                (focusin)="messageWrapper.classList.add('active')"
                (focusout)="messageWrapper.classList.remove('active')"
                (keyup)="keyBoardHandler($event)">
      </textarea>
    </div>
    <div class="message-send" *ngIf="!!messageGroup.get('message')?.value">
      <mat-icon (click)="sendMessageHandler()" svgIcon="send"></mat-icon>
    </div>
  </div>
</div>
<!--<input type="file" accept="image/*, .pdf, .mp4, .mov, .mpg, .flv, .avi, .mkv, .webm, .ogv, .ogg, .3gp" #inputFile class="hidden-input" (change)="uploadFile($event)">-->
<input type="file" accept="image/*, .pdf, .mp4, .webm, .mov, .ogv, .ogg" #inputFile class="hidden-input" (change)="uploadFile($event)">
