import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReactiveComponentModule } from '@ngrx/component';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from "ngx-image-cropper";

import { LyImageCropperModule } from "@alyle/ui/image-cropper";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HeaderComponent } from './components/header/header.component';
import { NotificationComponent } from './components/notification/notification.component';
import { BackActionComponent } from './components/back-action/back-action.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FooterComponent } from './components/footer/footer.component';
import { AnimatedLogoComponent } from "@shared/components/animated-logo/animated-logo.component";
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { PermissionPopupComponent } from '@modules/call/call-modals/permission/permission.component';
import { ButtonComponent } from './components/button/component/button.component';
import { CustomInputComponent } from './components/custom-input/component/custom-input.component';
import { CustomInputRadioComponent } from './components/custom-input-radio/component/custom-input-radio.component';
import { OnlyNumbersDirective } from "../directives/only-numbers.directive";
import { CustomSelectComponent } from './components/custom-select/component/custom-select.component';
import { NotTargetDirective } from "../directives/not-target.directive";
import { LanguageSelectComponent } from './components/language-select/component/language-select.component';
import { ChatBodyComponent } from './components/chat-body/chat-body.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { HmfLogoTemplateSvgComponent } from './templates/hmf-logo-template-svg/hmf-logo-template-svg.component';
import { PlayerModule } from '@shared/components/player/player.module';
import { VideoPreviewComponent } from './components/video-preview/video-preview.component';
import { MessageItemComponent } from './components/message-item/message-item.component';

export const translationConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
    deps: [HttpClient],
  },
  defaultLanguage: 'en',
};

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationComponent,
    BackActionComponent,
    PreloaderComponent,
    FooterComponent,
    AnimatedLogoComponent,
    SnackBarComponent,
    PermissionPopupComponent,
    OnlyNumbersDirective,
    NotTargetDirective,
    ButtonComponent,
    CustomInputComponent,
    CustomInputRadioComponent,
    CustomSelectComponent,
    LanguageSelectComponent,
    ChatBodyComponent,
    ChatMessageComponent,
    ImagePreviewComponent,
    HmfLogoTemplateSvgComponent,
    VideoPreviewComponent,
    MessageItemComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule.forRoot(translationConfig),
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    ImageCropperModule,
    RouterModule,
    LyImageCropperModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ReactiveComponentModule,
    MatTooltipModule,
    PlayerModule
  ],
  exports: [
    HeaderComponent,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    NotificationComponent,
    BackActionComponent,
    PreloaderComponent,
    FooterComponent,
    AnimatedLogoComponent,
    SnackBarComponent,
    PermissionPopupComponent,
    OnlyNumbersDirective,
    NotTargetDirective,
    ButtonComponent,
    CustomInputComponent,
    CustomInputRadioComponent,
    CustomSelectComponent,
    LanguageSelectComponent,
    ChatBodyComponent,
    ChatMessageComponent,
    MatTooltipModule,
    HmfLogoTemplateSvgComponent,
    PlayerModule
  ]
})
export class SharedModule {
  constructor(translate: TranslateService) {
    const currentLanguage = localStorage.getItem('currentLanguage');
    translate.setDefaultLang(currentLanguage || 'en');
  }
}
