import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ICustomInputRadioParameters } from "@shared/components/custom-input-radio/custom-input-radio.interfaces";

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomInputRadioComponent),
  multi: true,
};

@Component({
  selector: 'app-custom-input-radio',
  templateUrl: './custom-input-radio.component.html',
  styleUrls: ['./custom-input-radio.component.scss'],
  providers: [RADIO_VALUE_ACCESSOR]
})
export class CustomInputRadioComponent implements ControlValueAccessor {
  public componentParameters: ICustomInputRadioParameters;

  @Input() set radioParameters(data: ICustomInputRadioParameters) {
    this.componentParameters = data;
  }

  get radioParameters(): ICustomInputRadioParameters {
    return this.componentParameters;
  }

  public value: { text: string, status: boolean } = { text: '', status: false };
  public disableState = false;

  private onChange: (p: any) => void = () => {
  };
  private onTouched: (p: any) => void = () => {
  };

  writeValue(value: { text: string, status: boolean }[] | null): void {
    if (value === null) {
      this.value = { text: '', status: false };
      return;
    }
    this.value = value[0];
  }

  registerOnChange(fn: (p: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableState = isDisabled;
  }

  change(): void {
    const newValueState = { ...this.value, status: !this.value.status };
    this.value = newValueState;
    this.onChange(newValueState);
    this.onTouched(newValueState);
  }
}
