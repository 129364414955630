import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// Store
import { getWidgetData } from '../../../reducers/widget/widget.selectors';

// Services
import { ResizeDetectionService } from "@services/resize-detection.service";

// Models
import { IWidgetsData } from '@interfaces/widgets.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() color: 'white' | 'gray';
  @Input() isViewWidgetNumberBlock: boolean = true;

  public year: number = null;
  private date = new Date;

  public combined$: Observable<{ screenSize: string, widgetData: IWidgetsData }> = this.getCombineObservables();

  constructor(
    public store: Store,
    public resize: ResizeDetectionService
  ) {
  }

  ngOnInit(): void {
    this.year = this.date.getFullYear();
  }

  getCombineObservables(): Observable<{ screenSize: string, widgetData: IWidgetsData }> {
    return combineLatest([
      this.resize.screenSize.pipe(startWith(null)),
      this.store.select(getWidgetData).pipe(startWith(null))
    ])
      .pipe(map(([screenSize, widgetData]) => ({ screenSize, widgetData })));
  }
}
