// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  api_url: 'https://dev-ee2e434d.helpmefix.io/api',
  stripe: {
    publishKey: 'pk_test_51Gcp2HLRM5gxD7SjlfJ6ZqLut99TDEFPpbZX1rzl8pnI1HyVKkAJrVeTb1fRVNTJoqlmMT52lwBatknA84zNu1QO00Ts9q1wYB'
  },
  api_key: '47388671',
  api_secret: 'feef357f5f76da34be10c4ffc4115d76ec599bf4',
  ws_url: 'https://ws.dev-ee2e434d.helpmefix.io/',
  ws_channel_name: 'help_me_fix_dev_database_private-App.User.',
  countryAPI: 'https://ipinfo.io/json?token=86eab8f60d621b',
  firebase: {
    apiKey: "AIzaSyBZ6dc5UC8bosKTWs3ej1pmuyrOkPrHyis",
    authDomain: "helpmefix-dca49.firebaseapp.com",
    databaseURL: "https://helpmefix-dca49.firebaseio.com",
    projectId: "helpmefix-dca49",
    storageBucket: "helpmefix-dca49.appspot.com",
    messagingSenderId: "642697303611",
    appId: "1:642697303611:web:cb40a1f9a0cd80df71ae1e",
    measurementId: "G-0VGV1X1NR3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
