<div class="language-select-value" (click)="openSelect()" appNotTarget (clickOutside)="clickOutside()">
  <div class="icon" *ngIf="languagesList.length">
    <img [src]="'/assets/images/languages/' + languagesList[selectedIndex].key + '.png'" [alt]="languagesList[selectedIndex].key + '-logo'">
  </div>
  <div class="value">
    {{ value }}
  </div>
  <div class="icon-arrow-down" [class.active]="active">
    <mat-icon svgIcon="arrow-dropdown"></mat-icon>
  </div>

  <div class="language-select-options" [class.active]="active">
    <div class="option" *ngFor="let languageData of languagesList; index as i" (click)="change(languageData, i)">
      <img [src]="'/assets/images/languages/' + languageData.key + '.png'" [alt]="languageData.key + '-logo'">
      {{ languageData.language }}
    </div>
  </div>
</div>
