<ng-container *ngrxLet="combined$; let combined">
  <div *ngIf="combined.widgetData?.phone && combined.screenSize === 'desktop' && isViewWidgetNumberBlock" class="phone-help">
    <div class="phone-help-title">
      {{ 'phone_help.title' | translate }}
    </div>
    <div class="phone-help-number">
      <mat-icon svgIcon="phone"></mat-icon>
      <a [href]="'tel:'+combined.widgetData?.phone">{{ combined.widgetData?.phone }}</a>
    </div>
  </div>
  <div *ngIf="combined.widgetData?.phone && combined.screenSize !== 'desktop'" class="phone-help-footer">
    <div class="phone-help-title">
      {{ 'phone_help.title' | translate }}
    </div>
    <div class="phone-help-number">
      <a [href]="'tel:'+combined.widgetData?.phone">{{ combined.widgetData?.phone }}</a>
    </div>
  </div>
  <div class="footer" *ngIf="combined.screenSize === 'desktop'" [class.white]="color === 'white'"
       [class.widget-border-color]="combined.widgetData">
    <div class="footer__container d-flex justify-content-between align-items-center">

      <div class="footer__copyright" [class.widget-text]="combined.widgetData">
        {{ ("copyright" | translate) + year + ("trade" | translate) }}
      </div>

      <div class="footer__terms">
      <span>
        <a [routerLink]="['/privacy']">{{ "privacy" | translate }}</a>
      </span>

        <span>
        <a [routerLink]="['/terms']">{{ "terms" | translate }}</a>
      </span>
      </div>
    </div>
  </div>
</ng-container>
