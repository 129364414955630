import { Store } from '@ngrx/store';
import { NavigationStart, Router } from '@angular/router';

// Store
import { LoadProfileDataAction } from '../reducers/profile/profile.actions';
import { LoadPaymentCardsAction } from '../reducers/payments/payments.actions';
import { LoadCallHistoryAction } from '../reducers/call-history/call-history.actions';

// Services
import { UserService } from '@services/user.service';
import { WidgetsService } from '@services/widgets.service';

// Models
import { STORAGE_ITEMS } from '@consts/storage';
import { filter, first } from 'rxjs/operators';

export function initializeUser(userService: UserService, store: Store, router: Router) {
  return () => new Promise(resolve => {
    const userToken = localStorage.getItem(STORAGE_ITEMS.TOKEN);
    const userTemporaryToken = localStorage.getItem(STORAGE_ITEMS.TEMPORARY_TOKEN);
    router.events.pipe(
      first(),
      filter(event => event instanceof NavigationStart)
    ).subscribe({
      next: (event) => {
        const isDeeplink = event?.['url']?.includes('deeplink');
        userService.isAuthUser$.next((!!userToken || !!userTemporaryToken) && !isDeeplink);
        if (!!userToken && !isDeeplink) {
          store.dispatch(new LoadProfileDataAction());
          store.dispatch(new LoadCallHistoryAction(0, false));
          store.dispatch(new LoadPaymentCardsAction());
        }
      }
    });
    resolve(true);
  })
}

export function initializeWidget(widgetService: WidgetsService) {
  return () => widgetService.firstLoadWidget$.next(window.location.pathname.includes('widgets'));
}
