import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RouterService } from "@services/router.service";

@Component({
  selector: 'app-back-action',
  templateUrl: './back-action.component.html',
  styleUrls: ['./back-action.component.scss']
})
export class BackActionComponent implements OnInit, OnDestroy {

  @Input() text: string;
  @Input() lastUrl: string;
  @Input() noRouting: boolean;
  @Input() profileMode = true;
  @Output() onNavigateTo: EventEmitter<boolean> = new EventEmitter<boolean>();

  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    private routerService: RouterService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.checkLastUrl();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkLastUrl(): void {
    if (this.lastUrl) return;
    this.routerService.lastUrl
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        this.lastUrl = e;
      });
  }

  navigateTo(): void {
    this.onNavigateTo.emit(true);
    if (!this.profileMode) {
      this.router.navigate([`${ this.lastUrl }`]);
      return;
    }
    if (this.noRouting) return;
    if (this.lastUrl.includes('?')) {
      this.router.navigate([`${ this.lastUrl.split('?')[0] }`], {
        queryParams: { category: this.lastUrl.split('=')[1] }
      });
      return;
    }
    this.router.navigate([`${ this.lastUrl }`]);
  }
}
