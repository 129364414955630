import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";

// Store
import { ToggleLoaderStateAction } from '../reducers/loader/loader.actions';

// Services
import { UserService } from "@services/user.service";
import { WidgetsService } from '@services/widgets.service';

// Models
import { CLocalStorageDefaultKeys } from '@consts/global';
import { STORAGE_ITEMS } from '@consts/storage';
import { ClearSingleServiceStateAction } from '../reducers/single-service/single-service.actions';

export const USER_UNAUTHORIZED_CODE = 401;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private router: Router,
    private store: Store,
    private widgetsService: WidgetsService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isWidget = this.widgetsService.getWidgetStatus();
    const user = this.userService.getUser();
    const isLoggedIn = Object.keys(user).length && user?.token;
    const token = isLoggedIn ? user?.token : localStorage.getItem(STORAGE_ITEMS.TEMPORARY_TOKEN);
    if (request.url.includes('amazonaws')) {
      request = request.clone({
        setHeaders: {}
      });
      // } else if (isLoggedIn) {
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          'widget-code': isWidget ? this.widgetsService.getWidgetId().toString() : ''
        }
      });
    }

    if (request.url.includes('resend-activation-email')) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('temp_token')
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err?.status === USER_UNAUTHORIZED_CODE && !request.url.includes('/api/log')) {
          this.userService.logoutUser()
            .subscribe(() => {
              CLocalStorageDefaultKeys.forEach((key: string) => localStorage.removeItem(key));
              this.router.navigate(['/']);
              this.store.dispatch(new ClearSingleServiceStateAction());
              this.store.dispatch(new ToggleLoaderStateAction(false));
              localStorage.removeItem('token');
              localStorage.removeItem('deeplink_token');
            });
        }

        return throwError(err);
      })
    );
  }
}
