import { SessionActions, sessionActionsType } from './session.actions';

export const sessionFeatureKey = 'session';

export interface ISessionStateData {
  sessionId: string,
  callId: string,
  isMirror: boolean,
  isChatOn: boolean,
  isNotificationMessage: boolean,
  isRecordCall: boolean,
}

export interface IWorkerStateData {
  call_duration: number,
  call_id: number,
  call_rate: number,
  sender_first_name: string,
  sender_id: number,
  sender_last_name: string,
  sender_photo: string,
  session_id: string,
}

export interface IDevicesStateData {
  audioDeviceId: string,
  audioDeviceLabel: string,
  videoDeviceId: string,
  videoDeviceLabel: string,
  audio: boolean,
  video: boolean,
}

export interface ISessionState {
  session: ISessionStateData | null,
  worker: IWorkerStateData | null,
  devices: IDevicesStateData | null
}

export const initialState: ISessionState = {
  session: null,
  worker: null,
  devices: null
};

export const sessionReducer = (state = initialState, action: SessionActions) => {
  switch (action.type) {
    case sessionActionsType.setSessionParams:
      return {
        ...state,
        session: action.session
      };
    case sessionActionsType.setDevicesState:
      return {
        ...state,
        devices: action.devices
      };
    case sessionActionsType.setWorkerState:
      return {
        ...state,
        worker: action.worker
      };
    case sessionActionsType.clearWorkerState:
      return {
        ...state,
        worker: null
      };
    case sessionActionsType.toggleMirrorState:
      return {
        ...state,
        session: {
          ...state.session,
          isMirror: !state.session.isMirror
        }
      };
    case sessionActionsType.toggleChatState:
      return {
        ...state,
        session: {
          ...state.session,
          isChatOn: !state.session.isChatOn,
          isNotificationMessage: false
        }
      };
    case sessionActionsType.setNotificationMessageState:
      return {
        ...state,
        session: {
          ...state.session,
          isNotificationMessage: action.state
        }
      };
    case sessionActionsType.changeDevicesState:
      return {
        ...state,
        devices: {
          ...state.devices,
          ...action.devices
        }
      };
    default:
      return state;
  }
};
