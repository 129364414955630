<ng-container *ngrxLet="widgetData$, let widgetData">
  <div class="app-notification" [class.absolute]="absolute && errorText?.length <=80">
    <div class="hmf-error"
         [class.call-notification]="notificationType == 'call'"
         [style.backgroundColor]="getBackground(widgetData?.secondary_color)">
      <div class="message-wrapper">
        <div class="message">
          <span [style.color]="widgetData?.secondary_color">{{ errorText }}</span>
          <span class="resend" *ngIf="resendMode" [style.color]="widgetData?.secondary_color"
                (click)="emit()">{{ "resend" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
