<!--
   - Player Library https://videogular.github.io/ngx-videogular/docs/
-->

<div class="player-component" [class.thumbnail-video]="isThumbnail">

  <div *ngIf="isThumbnail && !isErrorLoad" class="thumbnail-video-play">
    <mat-icon svgIcon="play"></mat-icon>
  </div>

  <div *ngIf="isErrorLoad" class="thumbnail-video-error">
    <span class="error-message">{{ 'player.video-not-available' | translate }}</span>
  </div>

  <vg-player (onPlayerReady)="onPlayerReady($event)">
    <ng-container *ngIf="!isThumbnail">

      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>

      <vg-controls>
        <!--  Play/Pause control-->
        <vg-play-pause></vg-play-pause>

        <!--  Playback button control | speed video-->
        <!--        <vg-playback-button></vg-playback-button>-->

        <!--  Time display control | long base === current-->
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <!--  Scrub-bar control-->
        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

        <!--  Time display controls-->
        <!--    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>-->
        <!--    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>-->

        <!--  Subtitles control-->
        <!--    <vg-track-selector></vg-track-selector>-->

        <!--  Mute control-->
        <vg-mute></vg-mute>

        <!--  Volume control-->
        <vg-volume></vg-volume>

        <!--  Full screen control-->
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>

    </ng-container>

    <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto">

      <!--    Video sources-->
      <source *ngFor="let source of sources" [src]="source" [type]="type" (error)="onError($event)">

      <!--    Subtitles sources-->
      <!--    <track kind="subtitles" label="English" src="http://static.videogular.com/assets/subs/pale-blue-dot.vtt" srclang="en" default>-->
    </video>
  </vg-player>
</div>
