import { Action } from '@ngrx/store';

import { IAuthResp, IUDIDResp } from '@interfaces/user.interface';

export enum profileActionsType {
  setSocketToken = '[Profile] Set socket token object',
  loadProfileData = '[Profile] Load profile data object',
  clearProfileData = '[Profile] Clear profile data object',
  loadProfileDataSuccess = '[Profile] Load profile data object success',
  loadProfileDataError = '[Profile] Load profile data object error',
  updateProfileData = '[Profile] Update profile data object',
  updateProfileDataSuccess = '[Profile] Update profile data object success',
  updateProfileDataError = '[Profile] Update profile data object error',
  updateProfilePicture = '[Profile] Update profile picture',
  updateProfilePictureSuccess = '[Profile] Update profile picture success',
  updateProfilePictureError = '[Profile] Update profile picture error',
  deleteProfileData = '[Profile] Delete profile',
  deleteProfileDataError = '[Profile] Delete profile error',
  deleteProfilePicture = '[Profile] Delete profile picture',
  deleteProfilePictureSuccess = '[Profile] Delete profile picture success',
  deleteProfilePictureError = '[Profile] Delete profile picture error',
  logoutUser = '[Profile] Logout user',
  logoutUserError = '[Profile] Logout user error',
}


// Logout user
export class LogoutAction implements Action {
  readonly type = profileActionsType.logoutUser;
}

export class LogoutErrorAction implements Action {
  readonly type = profileActionsType.logoutUserError;

  constructor(public error: string) {
  }
}

// Update user profile
export class UpdateProfileDataAction implements Action {
  readonly type = profileActionsType.updateProfileData;

  constructor(public data: IAuthResp) {
  }
}

export class UpdateProfileDataSuccessAction implements Action {
  readonly type = profileActionsType.updateProfileDataSuccess;

  constructor(public data: IAuthResp) {
  }
}

export class UpdateProfileDataErrorAction implements Action {
  readonly type = profileActionsType.updateProfileDataError;

  constructor(public error: string) {
  }
}

// Delete user profile
export class DeleteProfileDataAction implements Action {
  readonly type = profileActionsType.deleteProfileData;

  constructor(public password: string) {
  }
}

export class DeleteProfileDataErrorAction implements Action {
  readonly type = profileActionsType.deleteProfileDataError;

  constructor(public error: string) {
  }
}

// Delete user profile picture
export class DeleteProfilePictureAction implements Action {
  readonly type = profileActionsType.deleteProfilePicture;
}

export class DeleteProfilePictureSuccessAction implements Action {
  readonly type = profileActionsType.deleteProfilePictureSuccess;
}

export class DeleteProfilePictureErrorAction implements Action {
  readonly type = profileActionsType.deleteProfilePictureError;

  constructor(public error: string) {
  }
}

// Update user profile picture
export class UpdateProfilePictureAction implements Action {
  readonly type = profileActionsType.updateProfilePicture;

  constructor(public data: { photo: string, photo_scale: number }) {
  }
}

export class UpdateProfilePictureSuccessAction implements Action {
  readonly type = profileActionsType.updateProfilePictureSuccess;

  constructor(public data: IUDIDResp) {
  }
}

export class UpdateProfilePictureErrorAction implements Action {
  readonly type = profileActionsType.updateProfilePictureError;

  constructor(public error: string) {
  }
}

// Socket token
export class SetSocketTokenAction implements Action {
  readonly type = profileActionsType.setSocketToken;

  constructor(public token: string | null) {
  }
}

// Load user profile
export class LoadProfileDataAction implements Action {
  readonly type = profileActionsType.loadProfileData;
}

export class ClearProfileDataAction implements Action {
  readonly type = profileActionsType.clearProfileData;
}

export class LoadProfileDataSuccessAction implements Action {
  readonly type = profileActionsType.loadProfileDataSuccess;

  constructor(public data: IAuthResp) {
  }
}

export class LoadProfileDataErrorAction implements Action {
  readonly type = profileActionsType.loadProfileDataError;

  constructor(public error: string) {
  }
}

export type ProfileActions = SetSocketTokenAction | LoadProfileDataAction |
  LoadProfileDataSuccessAction |
  LoadProfileDataErrorAction |
  ClearProfileDataAction |
  UpdateProfileDataAction |
  UpdateProfileDataErrorAction |
  UpdateProfileDataSuccessAction |
  DeleteProfilePictureAction |
  DeleteProfilePictureSuccessAction |
  DeleteProfilePictureErrorAction |
  DeleteProfileDataAction |
  DeleteProfileDataErrorAction |
  UpdateProfilePictureAction |
  UpdateProfilePictureSuccessAction |
  UpdateProfilePictureErrorAction;
