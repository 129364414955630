import { IWidgetsData } from '@interfaces/widgets.interface';
import { WidgetActions, widgetActionsType } from './widget.actions';

export const widgetFeatureKey = 'widget';

export interface IWidgetState {
  isWidget: boolean,
  data: IWidgetsData | null,
  isFetching: boolean,
  isError: boolean,
}

export const initialState: IWidgetState = {
  isWidget: false,
  data: null,
  isFetching: true,
  isError: false,
};

export const widgetReducer = (state = initialState, action: WidgetActions) => {
  switch (action.type) {
    case widgetActionsType.loadWidgetData:
      return {
        ...state,
        isWidget: true,
        isFetching: true,
      };
    case widgetActionsType.loadWidgetDataSuccess:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        isError: false,
      };
      case widgetActionsType.loadWidgetDataError:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};
