import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { CommonTermAndPolicyModel } from "@interfaces/user.interface";

@Injectable()
export class CommonService {

  constructor(private http: HttpClient) {}

  public getTermsAndCondition(): Observable<CommonTermAndPolicyModel> {
    return this.http.get<CommonTermAndPolicyModel>(`/static-pages/terms-and-conditions`);
  }
  public getPrivacyPolicy(): Observable<CommonTermAndPolicyModel> {
    return this.http.get<CommonTermAndPolicyModel>(`/static-pages/privacy-policy`);
  }
}

