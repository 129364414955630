import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from "@angular/router";

import { filter, pairwise } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  public lastUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private router: Router) {
    this.checkRouterEvents();
  }

  checkRouterEvents(): void {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        if (!e[1].url.includes('call-process')) {
          localStorage.removeItem('tb_data');
        }
        this.lastUrl.next(e[0].urlAfterRedirects);
      });
  }
}
