<div class="item-message" [class.image-message]="imageMessageClass" [class.video-message]="videoMessageClass">
  <!--Text message-->
  <ng-container *ngIf="!!message?.message"
                [ngTemplateOutlet]="textMessage"
                [ngTemplateOutletContext]="{ $implicit: message }">
  </ng-container>

  <!--File message-->
  <ng-container *ngIf="!!message?.attachment">

    <!--Document file-->
    <ng-container *ngIf="isDocumentFile(message?.attachment.type)"
                  [ngTemplateOutlet]="documentMessage"
                  [ngTemplateOutletContext]="{ $implicit: message }">
    </ng-container>

    <!--Image file-->
    <ng-container *ngIf="isImageFile(message?.attachment.type)"
                  [ngTemplateOutlet]="imageMessage"
                  [ngTemplateOutletContext]="{ $implicit: message }">
    </ng-container>

    <!--Video file-->
    <ng-container *ngIf="isVideoFile(message?.attachment.type)"
                  [ngTemplateOutlet]="videoMessage"
                  [ngTemplateOutletContext]="{ $implicit: message }">
    </ng-container>

  </ng-container>
</div>

<!--Text message template-->
<ng-template #textMessage let-messageData>
  <div class="item-message-text">
    {{ messageData.message }}
  </div>
  <ng-container [ngTemplateOutlet]="messageTime"
                [ngTemplateOutletContext]="{ $implicit: messageData }">
  </ng-container>
</ng-template>

<!--Image message template-->
<ng-template #imageMessage let-messageData>
  <div class="item-message-file" (click)="openFile(messageData)">
    <div class="file-item">
      <div class="file-item-image" *ngIf="isLoad && isImageFile(messageData?.attachment.type)">
        <img [src]="messageData?.attachment.url" alt="user-uploaded-image">
      </div>
      <div class="file-item-progress" *ngIf="!isLoad">
        <img src="/assets/images/file-loading.gif" alt="file-load">
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoad" [ngTemplateOutlet]="messageTime"
                [ngTemplateOutletContext]="{ $implicit: messageData }"></ng-container>
</ng-template>

<!--Document message template-->
<ng-template #documentMessage let-messageData>
  <div class="item-message-file"
       (click)="openFile(messageData)">
    <div class="file-item">
      <div class="file-item-icon" *ngIf="isLoad">
        <mat-icon svgIcon="file"></mat-icon>
      </div>
      <div class="file-item-progress" *ngIf="!isLoad">
        <img src="/assets/images/file-loading.gif" alt="file-load">
      </div>
    </div>
    <div class="file-item">
      <div class="file-item-name">
        {{ messageData?.attachment.name }}
      </div>
      <div class="file-item-size">
        {{ getFileSize(messageData?.attachment.size) }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoad" [ngTemplateOutlet]="messageTime"
                [ngTemplateOutletContext]="{ $implicit: messageData }"></ng-container>
</ng-template>

<!--Video message template-->
<ng-template #videoMessage let-messageData>
  <div class="item-message-file"
       (click)="openFile(messageData)">
    <div class="file-item">
      <div *ngIf="isLoad" class="file-item-video">
        <app-player (onErrorLoad)="onErrorLoadHandler($event)" [sources]="[messageData?.attachment?.url]"
                    [isThumbnail]="true" [type]="messageData?.attachment?.type?.replace('quicktime', 'mp4')"></app-player>
      </div>
      <div class="file-item-progress" *ngIf="!isLoad">
        <img src="/assets/images/file-loading.gif" alt="file-load">
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoad" [ngTemplateOutlet]="messageTime"
                [ngTemplateOutletContext]="{ $implicit: messageData }"></ng-container>
</ng-template>

<!--Message time template-->
<ng-template #messageTime let-data>
  <div class="item-message-time">
    {{ data?.message_time }}
    <ng-container [ngSwitch]="data?.message_status">
      <mat-icon *ngSwitchCase="EChatMessageStatus.Delivered" svgIcon="check-double"></mat-icon>
      <mat-icon *ngSwitchCase="EChatMessageStatus.Read" class="color-icon" svgIcon="check-double"></mat-icon>
      <mat-icon *ngSwitchCase="EChatMessageStatus.Sent" svgIcon="check-one"></mat-icon>
    </ng-container>
  </div>
</ng-template>
