import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';

// Components
import { ImagePreviewComponent } from '@shared/components/image-preview/image-preview.component';
import { VideoPreviewComponent } from '@shared/components/video-preview/video-preview.component';

// Models
import { IChatMsg } from '@interfaces/temporary-user.interface';
import { EChatMessageStatus } from '../../../enums/global.enums';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent {
  public readonly EChatMessageStatus = EChatMessageStatus;

  @Input() message: IChatMsg;
  @Input() userId: number;

  public isErrorLoad: boolean = false;
  public isMobile = this.deviceDetectorService.isMobile(window.navigator.userAgent);

  get isLoad(): boolean {
    return !!this.message?.isLoad;
  }

  get imageMessageClass(): boolean {
    return this.isLoad && this.isImageFile(this.message?.attachment?.type);
  }

  get videoMessageClass(): boolean {
    return this.isLoad && this.isVideoFile(this.message?.attachment?.type);
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog
  ) {
  }

  onErrorLoadHandler(status: boolean): void {
    this.isErrorLoad = true;
  }

  isDocumentFile(type: string | undefined): boolean {
    return type?.includes('application');
  }

  isImageFile(type: string | undefined): boolean {
    return type?.includes('image');
  }

  isVideoFile(type: string | undefined): boolean {
    return type?.includes('video');
  }

  openFile({ attachment: { type, name, url } }: IChatMsg): void {
    if (this.isErrorLoad) return;
    if (this.isImageFile(type)) {
      this.openImagePreview(name, url);
      return;
    }
    if (this.isVideoFile(type)) {
      this.openVideoPreview(name, url, type);
      return;
    }
    this.openPDFFile(url);
  }

  getFileSize(size: string | undefined): string {
    if (!size) return '';
    if (+size < 10000) {
      return (+size / 1000).toFixed(2) + ' Kb';
    }
    return (+size / 1000000).toFixed(2) + ' Mb';
  }

  openImagePreview(name: string, url: string): void {
    this.dialog.open(ImagePreviewComponent, {
      panelClass: this.isMobile ? 'full-screen-dialog' : '',
      data: { image: { name, url }, isMobile: this.isMobile }
    });
  }

  openVideoPreview(name: string, url: string, type: string): void {
    this.dialog.open(VideoPreviewComponent, {
      panelClass: this.isMobile ? 'full-screen-dialog' : '',
      data: { video: { name, url, type }, isMobile: this.isMobile }
    });
  }

  openPDFFile(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }
}
