import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CLanguagesList } from '@shared/components/language-select/language-select.constantes';

export const SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LanguageSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  providers: [SELECT_VALUE_ACCESSOR]
})
export class LanguageSelectComponent implements ControlValueAccessor {
  public languagesList: { key: string, language: string }[] = CLanguagesList;
  public selectedIndex: number = 0;
  public active: boolean = false;

  public value: string | null = this.languagesList[0].language;
  public disableState = false;
  private onChange: (p: any) => void = () => {
  };
  private onTouched: (p: any) => void = () => {
  };

  clickOutside(): void {
    this.active = false;
  }

  openSelect(): void {
    this.active = !this.active;
  }

  writeValue(languageKey: string | null): void {
    if (!languageKey) {
      this.emitValue();
      return;
    }
    this.value = this.languagesList.find(data => data.key === languageKey).language;
  }

  registerOnChange(fn: (p: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableState = isDisabled;
  }

  change(data: { key: string, language: string }, index: number): void {
    this.selectedIndex = index;
    this.value = data.language;
    this.onChange(data.language);
    this.onTouched(data.language);
  }

  emitValue(): void {
    this.onChange(this.value);
    this.onTouched(this.value);
  }
}
