/**
 * Created by Oleksandr V. on 6/16/2022.
 * Copyright © 2022 [Spotlyve]. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// Store
import { LoadServicesStateActionError, LoadServicesStateActionSuccess, servicesActionsType } from './services.actions';
import { ToggleLoaderStateAction } from '../loader/loader.actions';

// Services
import { SystemNotificationsService } from '@services/system-notifications.service';
import { UserCallsService } from '@services/user-calls.service';

// Models
import { IIconData } from '@consts/global';


@Injectable()
export class ServicesEffects {

  constructor(
    private actions$: Actions,
    private store$: Store,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private systemNotificationsService: SystemNotificationsService,
    private sanitizer: DomSanitizer,
    private userCallService: UserCallsService,
  ) {
  }

  loadServicesList$ = createEffect(() => this.actions$.pipe(
    ofType(servicesActionsType.loadServicesState),
    switchMap(({ widgetId }) => {
      this.store$.dispatch(new ToggleLoaderStateAction(true));
      return this.userCallService.userGetServices()
        .pipe(
          map((servicesData) => {
            this.registerIcons(servicesData.services);
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            return new LoadServicesStateActionSuccess(servicesData);
          }),
          catchError((error) => {
            this.userCallService.sendErrorCall(error);
            return of(new LoadServicesStateActionError(error));
          })
        );
    })
  ));

  registerIcons(listIcons: IIconData[]): void {
    listIcons.forEach(item => {
      if (item?.icon_svg) {
        this.iconRegistry.addSvgIcon(item?.name, this.getSecurityUrl(item?.icon_svg));
      }
    });
  }

  getSecurityUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url) as SafeResourceUrl;
  }
}
