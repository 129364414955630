<div class="image-preview" [class.full-screen]="isMobile">
  <div class="image-preview-close" *ngIf="!isMobile" (click)="closeDialog()">
    <mat-icon svgIcon="close"></mat-icon>
  </div>
  <div class="image-preview-title" [class.mobile]="isMobile"
       (click)="isMobile ? closeDialog(): $event.preventDefault()">
    <mat-icon *ngIf="isMobile" svgIcon="chevron"></mat-icon>
    {{ previewImage.name }}
  </div>
  <div class="image-preview-image">
    <img #imageElement [src]="previewImage.url" alt="image-preview">
  </div>
  <div class="image-preview-actions">
    <mat-icon svgIcon="mono-rotate" class="rotate-left" (click)="rotateImage(imageElement, EDirectionImage.Left)"></mat-icon>
    <mat-icon svgIcon="mono-rotate" class="rotate-right" (click)="rotateImage(imageElement, EDirectionImage.Right)"></mat-icon>
  </div>
</div>
