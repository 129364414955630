<div class="video-preview" [class.full-screen]="isMobile">
  <div *ngIf="!isMobile"
       class="video-preview-close"
       (click)="closeDialog()">
    <mat-icon svgIcon="close"></mat-icon>
  </div>
  <div class="video-preview-title"
       [class.mobile]="isMobile"
       (click)="isMobile ? closeDialog(): $event.preventDefault()">
    <mat-icon *ngIf="isMobile" svgIcon="chevron"></mat-icon>
    {{ previewData.name }}
  </div>
  <div class="video-preview-video">
    <app-player [sources]="[previewData.url]" [type]="previewData.type?.replace('quicktime', 'mp4')"></app-player>
  </div>
</div>
