import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

// Store
import { getWidgetData } from '../../../reducers/widget/widget.selectors';

// Models
import { IWidgetsData } from '@interfaces/widgets.interface';
import { ThemeService } from '@services/theme/theme.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() errorText: string;
  @Input() resendMode: boolean;
  @Input() absolute: boolean = false;
  @Input() notificationType: 'call' | 'error';
  @Output() resend: EventEmitter<any> = new EventEmitter<any>();

  public widgetData$: Observable<IWidgetsData> = this.store.select(getWidgetData);

  constructor(
    private store: Store,
    private themeService: ThemeService,
  ) {
  }

  public emit(): void {
    this.resend.emit()
  }

  getBackground(hexCode: string): string {
    return this.themeService.getHexColor(hexCode, false);
  }
}
