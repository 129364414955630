/**
 * Created by Oleksandr V. on 6/16/2022.
 * Copyright © 2022 [Spotlyve]. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Action } from '@ngrx/store';

// Store
import {
  widgetActionsType,
  LoadWidgetDataSuccessAction,
  LoadWidgetDataErrorAction, LoadWidgetDataAction
} from './widget.actions';

// Services
import { WidgetsService } from '@services/widgets.service';
import { ThemeService } from '@services/theme/theme.service';

// Models
import { STORAGE_ITEMS } from '@consts/storage';

@Injectable()
export class WidgetEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private widgetsService: WidgetsService,
    private themeService: ThemeService,
  ) {
  }

  ngrxOnInitEffects(): Action {
    const widgetId = this.widgetsService.getWidgetId();
    if (!widgetId) return { type: '' };
    sessionStorage.setItem(STORAGE_ITEMS.WIDGET_ID, widgetId.toString());
    return new LoadWidgetDataAction(widgetId);
  }

  loadWidgetData$ = createEffect(() => this.actions$.pipe(
    ofType(widgetActionsType.loadWidgetData),
    switchMap(({ widgetId }) => {
      return this.widgetsService.getWidgetDataFromServer(widgetId)
        .pipe(
          map((widgetData) => {
            this.themeService.setCustomTheme(widgetData);
            if (widgetData?.homeowner_access) {
              localStorage.setItem('homeowner_access', '1');
            } else {
              localStorage.removeItem('homeowner_access');
            }
            return new LoadWidgetDataSuccessAction(widgetData);
          }),
          catchError((error) => {
            localStorage.removeItem('homeowner_access');
            return of(new LoadWidgetDataErrorAction(error));
          })
        );
    })
  ));
}
