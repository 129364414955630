import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private isFocused: boolean = true;
  private appTitle: string = 'Help me Fix';
  private faviconElement: HTMLLinkElement = document.querySelector('#favicon');
  private faviconHref = 'favicon.ico';

  constructor(private titleService: Title) {
    window.addEventListener('blur', this.checkBlurTab.bind(this));
    window.addEventListener('focus', this.checkFocusTab.bind(this));
  }

  changeTitle(title: string = 'You have new message!'): void {
    if (this.isFocused) return;
    this.titleService.setTitle(title);
    this.faviconElement.href = '/assets/notification-favicon.png';
  }

  checkBlurTab(event: any): void {
    this.isFocused = false;
  }

  checkFocusTab(event: any): void {
    this.changeTitle(this.appTitle);
    this.faviconElement.href = this.faviconHref;
    this.isFocused = true;
  }
}
