<ng-container *ngrxLet="screenSize$; let screenSize">
  <div class="header w-100"
       [class.no_border]="openedSubmenu"
       [class.full-height]="emailNeedUpdate"
       [class.tablet]="screenSize === 'tablet'"
       [class.desktop]="screenSize === 'desktop'">

    <div *ngIf="emailNeedUpdate" class="email-need-update">
      <p>{{ 'header.need-email.part_one' | translate }}</p>
      <p>{{ 'header.need-email.part_two' | translate }}
        <a [routerLink]="['/user-profile/main']">
          {{ 'header.need-email.link' | translate }}
        </a>
      </p>
    </div>

    <div class="header__container d-flex justify-content-between align-items-center">
      <div *ngIf="!isSafari || isWidget; else safariLogo" class="header__logo"
           [routerLink]="['/home-page/main-page']" (click)="logoClickHandler()"></div>
      <div class="header__menu" #menuButton [class.no_vision]="screenSize === 'desktop'"
           (click)="toggleBurgerMenu()">
        <mat-icon [svgIcon]="'menu-burger'"></mat-icon>
      </div>

      <div class="header__list d-flex align-items-center" *ngIf="screenSize === 'desktop'">
        <ng-container *ngIf="!isTemporary">
          <div class="header__list-profile" [routerLink]="['/home-page/main-page']"
               routerLinkActive="active-link">
            {{ "profile.home" | translate }}
          </div>
          <div class="header__list-profile" (click)="loaderAction(true, $event.target)"
               [routerLink]="['/user-profile/main']"
               routerLinkActive="active-link">
            {{ "profile.profile" | translate }}
          </div>
          <div class="header__list-call" [routerLink]="['/call/call-history']"
               routerLinkActive="active-link">
            {{ "profile.call_history" | translate }}
          </div>
          <div class="header__button">
            <app-button [buttonParameters]="configList.buttons.engineer"
                        [routerLink]="['/home-page/categories']">
              {{ "home-page.engineer" | translate }}
            </app-button>
          </div>
        </ng-container>

        <div *ngIf="isTemporary" class="header__list-no-auth-menu">
          <app-button [buttonParameters]="configList.buttons.login"
                      [routerLink]="['/auth/sign-in']">{{ "sign_in.login" | translate }}</app-button>
          <app-button [buttonParameters]="configList.buttons.signUp"
                      [routerLink]="['/auth/sign-up']">{{ "sign_up.sign_up" | translate }}</app-button>
        </div>
      </div>
    </div>
    <div class="header__submenu" [class.height_none]="!openedSubmenu" #menuContainer>
      <div class="header__submenu-container">
        <nav>
          <ul *ngIf="!isTemporary">
            <li *ngFor="let item of submenu">
              <a (click)="loaderAction(item?.loader, $event.target)"
                 [routerLink]="item.path"
                 routerLinkActive="active-link">{{ item.title }}</a>
            </li>
          </ul>
          <ul *ngIf="isTemporary">
            <li>
              <app-button [buttonParameters]="configList.buttons.login"
                          [routerLink]="['/auth/sign-in']">{{ "sign_in.login" | translate }}</app-button>
            </li>
            <li>
              <app-button [buttonParameters]="configList.buttons.signUp"
                          [routerLink]="['/auth/sign-up']">{{ "sign_up.sign_up" | translate }}</app-button>
            </li>
          </ul>
        </nav>

        <div *ngIf="!isTemporary" class="header__button">
          <app-button [buttonParameters]="configList.buttons.engineer"
                      [routerLink]="['/home-page/categories']">
            {{ "home-page.engineer" | translate }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #safariLogo>
  <div class="header__logo-svg" (click)="logoClickHandler()" [routerLink]="['/home-page/main-page']">
    <app-hmf-logo-template-svg></app-hmf-logo-template-svg>
  </div>
</ng-template>
