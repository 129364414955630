<div class="back-action__back d-flex align-items-center justify-content-between" *ngIf="lastUrl" (click)="navigateTo()">
  <div class="back-action__back-chevron">
    <mat-icon [svgIcon]="'chevron'"></mat-icon>
  </div>
  <div class="back-action__back-text" *ngIf="text">
    {{ text | translate }}
  </div>
</div>

<div class="back-action__back d-flex align-items-center justify-content-between" *ngIf="!lastUrl"
     (click)="navigateTo()">
  <div class="back-action__back-chevron">
    <mat-icon [svgIcon]="'chevron'"></mat-icon>
  </div>
  <div class="back-action__back-text" *ngIf="text">
    {{ text | translate }}
  </div>
</div>
