import { EButtonColorTypes, EButtonTypes } from '@shared/components/button/button.enums';

export const CCallModalsConfig = {
  buttons: {
    ok: {
      type: EButtonTypes.Medium,
      colorType: EButtonColorTypes.Fill
    },
    pay: {
      type: EButtonTypes.Medium,
      colorType: EButtonColorTypes.Fill
    },
    end: {
      type: EButtonTypes.Medium,
      colorType: EButtonColorTypes.Empty
    },
    connect: {
      type: EButtonTypes.Medium,
      colorType: EButtonColorTypes.Fill
    },
    cancel: {
      type: EButtonTypes.Medium,
      colorType: EButtonColorTypes.Empty
    },
  }
}
