import { Component, Input } from '@angular/core';

import { IButtonParameters } from "@shared/components/button/button.interfaces";
import { EButtonColorTypes, EButtonTypes } from "@shared/components/button/button.enums";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  public readonly EButtonTypes = EButtonTypes;
  public readonly EButtonColorTypes = EButtonColorTypes;

  public componentParameters: IButtonParameters;

  @Input() disableState?: boolean;

  @Input() set buttonParameters(data: IButtonParameters) {
    this.componentParameters = data;
  }

  get buttonParameters(): IButtonParameters {
    return this.componentParameters;
  }
}
